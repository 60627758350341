import React, { FC } from "react"

import { SVG } from "./SVG"

export const SeoIcon: FC = props => (
  <SVG {...props} width="55" height="59" viewBox="0 0 55 59" fill="none">
    <circle
      cx="22.2341"
      cy="27.7097"
      r="20.7341"
      stroke="#492CA9"
      strokeWidth="3"
    />
    <circle cx="22.2339" cy="27.7098" r="18.7234" fill="#492CA9" />
    <circle
      cx="22.2339"
      cy="27.7098"
      r="17.2234"
      stroke="white"
      strokeWidth="3"
    />
    <mask
      id="seoIconMask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="0"
      width="52"
      height="47"
      style={{ maskType: "alpha" }}
    >
      <path
        d="M54.9743 22.7014C54.9743 33.4557 33.5854 46.4333 22.8851 46.4333C12.1848 46.4333 3.5105 37.7152 3.5105 26.9609C3.5105 16.2066 34.5867 0.794922 45.287 0.794922C55.9873 0.794922 54.9743 11.9471 54.9743 22.7014Z"
        fill="#492CA9"
      />
    </mask>
    <g mask="url(#seoIconMask0)">
      <path
        d="M28.4529 24.0697C26.9262 26.4498 23.6613 28.7312 19.5951 30.447C15.4986 32.1755 10.7616 33.2562 6.58783 33.3075C5.63451 33.3192 4.93797 33.9491 4.64024 34.6438C4.34 35.3443 4.36796 36.3077 5.09914 36.9872L11.6437 43.0693C11.8763 43.2855 12.1608 43.4447 12.4672 43.5296C21.0662 45.9134 28.2976 44.0183 33.7498 40.6586C39.1702 37.3186 42.8506 32.523 44.4294 29.0149C44.4402 28.9908 44.4595 28.9711 44.4842 28.9593C44.5081 28.9479 44.5279 28.9479 44.5433 28.9523C46.2332 29.4276 49.2277 30.1471 51.3842 30.3843C52.7129 30.5304 53.6584 29.3484 53.4593 28.1487C52.1154 20.0529 49.6093 8.74917 48.1703 3.62553C47.7891 2.26826 46.1966 1.77336 45.0961 2.56116C40.4667 5.87497 28.6708 13.7766 22.1962 17.6865C20.8222 18.5162 20.921 20.5549 22.3835 21.2374L28.4529 24.0697Z"
        fill="#492CA9"
        stroke="white"
        strokeWidth="2"
      />
    </g>
    <mask
      id="seoIconMask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="21"
      width="25"
      height="26"
      style={{ maskType: "alpha" }}
    >
      <path
        d="M22.2339 40.5825C23.9893 44.0938 32.5746 46.4332 22.2339 46.4332C11.8933 46.4332 3.5105 38.0504 3.5105 27.7097C3.5105 17.369 12.2871 23.0279 15.7978 25.3691C19.3084 27.7103 20.4786 37.0711 22.2339 40.5825Z"
        fill="white"
      />
    </mask>
    <g mask="url(#seoIconMask1)">
      <circle
        cx="22.2339"
        cy="27.7098"
        r="17.2234"
        stroke="white"
        strokeWidth="3"
      />
    </g>
    <path
      d="M39.6161 45.1916C39.8446 45.4201 40.2151 45.4201 40.4436 45.1916C40.6721 44.9631 41.0425 44.9631 41.271 45.1916L49.975 53.8956C50.7561 54.6767 50.7561 55.943 49.975 56.7241L49.4936 57.2055C48.7125 57.9865 47.4462 57.9865 46.6652 57.2055L37.9612 48.5015C37.7327 48.273 37.7327 47.9025 37.9612 47.674C38.1897 47.4455 38.1897 47.0751 37.9612 46.8466L35.6917 44.5771C35.2347 44.1201 35.2347 43.3792 35.6917 42.9222C36.1487 42.4652 36.8897 42.4652 37.3467 42.9222L39.6161 45.1916Z"
      fill="#492CA9"
    />
  </SVG>
)
