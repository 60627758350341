import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareRounded14Icon: FC = props => (
  <SVG {...props} width="46" height="46" viewBox="0 0 46 46" fill="none">
    <rect
      x="5.24829"
      y="5.78076"
      width="35"
      height="35"
      rx="14"
      stroke="currentColor"
      strokeWidth="10"
    />
  </SVG>
)
