import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorShapeToothSharp: FC = props => (
  <SVG {...props} width="521" height="229" viewBox="0 0 521 229" fill="none">
    <path
      d="M191.187 220.28C243.565 192.733 313.203 147.267 438.029 147.267L520.711 102.297C365.381 19.9896 -10.1586 -67.6636 0.210938 78.5346C9.04105 203.029 138.808 247.827 191.187 220.28Z"
      fill="url(#VectorShapeToothSharp)"
      fillOpacity="0.58"
    />
    <defs>
      <linearGradient
        id="VectorShapeToothSharp"
        x1="296.345"
        y1="-121.804"
        x2="13.9192"
        y2="55.8256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E83D1" />
        <stop offset="1" stopColor="#9E5DF1" />
      </linearGradient>
    </defs>
  </SVG>
)
