import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareRounded50Icon: FC = props => (
  <SVG {...props} width="209" height="209" viewBox="0 0 209 209" fill="none">
    <rect
      x="20.6313"
      y="20.6313"
      width="167.506"
      height="167.506"
      rx="50"
      stroke="currentColor"
      strokeWidth="40"
    />
  </SVG>
)
