import React, { FC } from "react"

import { SVG } from "./SVG"

export const ProcessPointerBackIcon: FC = props => (
  <SVG {...props} width="124" height="62" viewBox="0 0 124 62" fill="none">
    <mask
      id="ProcessPointerBackIconMask01"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="124"
      height="62"
      mask-type="alpha"
      style={{ maskType: "alpha" }}
    >
      <path
        d="M11 62C11 33.8335 33.8335 11 62 11C90.1665 11 113 33.8335 113 62H124C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62H11Z"
        fill="white"
      />
    </mask>
    <g mask="url(#ProcessPointerBackIconMask01)">
      <path
        d="M-7.80359 68.4559L1.09555 82.7057L17.3201 78.3452L36 30.8893L10.8763 21L-7.80359 68.4559Z"
        fill="url(#ProcessPointerBackIconLinearGradient01)"
      />
      <path
        d="M12.8455 13.0625L15.3204 29.6795L31.9374 32.1544L67.9998 -3.90809L48.9079 -23L12.8455 13.0625Z"
        fill="url(#ProcessPointerBackIconLinearGradient02)"
      />
      <path
        d="M40.9316 -4.58583L36.9989 11.3592L50.5447 20.6449L95.1149 3.66325L85.5018 -21.5674L40.9316 -4.58583Z"
        fill="url(#ProcessPointerBackIconLinearGradient03)"
      />
      <path
        d="M85.9341 -2.71893L73.3051 6.11384L74.0169 21.5088L108.036 38.2422L119.953 14.0145L85.9341 -2.71893Z"
        fill="url(#ProcessPointerBackIconLinearGradient04)"
      />
      <path
        d="M118.948 21.9328L102.15 22.2196L97.5294 38.3719L128.581 78.8292L150 62.3901L118.948 21.9328Z"
        fill="url(#ProcessPointerBackIconLinearGradient05)"
      />
    </g>
    <defs>
      <linearGradient
        id="ProcessPointerBackIconLinearGradient01"
        x1="-7.62045"
        y1="67.9907"
        x2="22.614"
        y2="49.704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.81" />
        <stop offset="1" stopColor="#B9B9B9" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerBackIconLinearGradient02"
        x1="13.199"
        y1="12.7089"
        x2="48.2188"
        y2="8.00355"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#B9B9B9" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerBackIconLinearGradient03"
        x1="41.3686"
        y1="-4.75232"
        x2="74.1025"
        y2="3.80885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#B9B9B9" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerBackIconLinearGradient04"
        x1="86.2676"
        y1="-2.55487"
        x2="102.439"
        y2="21.2023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#CCCCCC" />
        <stop offset="1" stopColor="#B9B9B9" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerBackIconLinearGradient05"
        x1="119.252"
        y1="22.3294"
        x2="119.351"
        y2="57.6638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#B9B9B9" />
      </linearGradient>
    </defs>
  </SVG>
)
