import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import { TopNavigation } from "./TopNavigation"
import { Footer } from "./footer"

import { mainTheme } from "./theme"
import "../css/layout.css" // just reset file dont add more rules here
import "../css/font.css"

const Providers: React.FC = ({ children }) => (
  <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>
)

type Props = {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <Providers>
      <GlobalStyle />
      <TopNavigation />
      <main>{children}</main>
      {/* <Footer /> */}
    </Providers>
  )
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    position: relative;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    scroll-behavior: smooth;
  }

  img {
    padding: 0;
    margin: 0;
  }
  
  h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
    font-family: Nunito;
    cursor: default;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    user-select: none;
  }
`

export default Layout
