import { keyframes as styledKeyframes, Keyframes } from "styled-components"

export const spaces = {
  xs: "2px",
  s: "4px",
  m: "8px",
  l: "16px",
  xl: "32px",
  xxl: "64px",
  xxxl: "128px",
}

export const deviceBreakpoints = {
  mobile: "576px",
  smallTablet: "768px",
  tablet: "992px",
  bigTablet: "1200px",
  laptop: "1440px",
  smallDesktop: "1600px",
  desktop: "1920px",
}

export const sizes = {}

export const colors = {
  transparent: "transparent",

  // Black
  black: "#000000",
  dark: "#2B2B2B",
  darker: "#1B1B1B",

  // White
  white: "#FFFFFF",

  // Gray
  borderGray: "#e7e7e7",
  grayLight: "#7C839C",

  // Purple
  purple: "#6442D3",
  darkerPurple: "#5f2ea9",
  darkPurple: "#4B239E",
  blueishPurple: "#492CA9",

  // Blue
  lightBlue: "#F2F4FC",

  // Vine
  vine: "#7F3D9C",
  purpleVine: "#753AD9",
  darkVine: "#6F1597",
  deepVine: "#2C0C6D",

  // Yellow
  yellow: "#FAD24D",

  // Orange
  orange: "#F2994A",
}

export const getColor = (color: keyof typeof colors) => colors[color]

export const keyframes = {
  opacity: styledKeyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  scale: styledKeyframes`
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  `,
  fadeUp: styledKeyframes`
    from {
      opacity: 0.3;
      transform: translate3d(0, 4px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  halfOpacity: styledKeyframes`
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  `,
}

export const transitions = {
  extraFastEase: "80ms ease",
  fastEase: "150ms ease",
  mediumEase: "280ms ease",
  mediumLinear: "280ms linear",
  slowEase: "600ms ease",
  fastBezier: "150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
}

export type Theme = {
  spaces: Record<keyof typeof spaces, string>
  deviceBreakpoints: Record<keyof typeof deviceBreakpoints, string>
  sizes: Record<keyof typeof sizes, string | number>
  colors: Record<keyof typeof colors, string>
  keyframes: Record<keyof typeof keyframes, Keyframes>
  transitions: Record<keyof typeof transitions, string>
}

export const mainTheme: Theme = {
  spaces,
  deviceBreakpoints,
  sizes,
  colors,
  keyframes,
  transitions,
}
