import React, { FC } from "react"

import { SVG } from "./SVG"

export const HamburgerMenuIcon: FC = props => (
  <SVG {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 12H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)
