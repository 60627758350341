import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndChart: FC = props => (
  <SVG {...props} width="463" height="445" viewBox="0 0 463 445" fill="none">
    <path
      opacity="0.07"
      d="M406.939 224.839C406.939 307.122 358.792 345.984 299.433 311.74C240.074 277.495 191.987 182.978 191.987 100.755C191.987 18.4716 240.134 -20.3909 299.493 13.8537C358.852 48.0983 406.939 142.556 406.939 224.839Z"
      fill="url(#SlideCardsAndChart_linear_0)"
    />
    <path
      opacity="0.4"
      d="M250.687 51.8164C236.536 43.6601 222.566 39.462 209.555 39.402C196.544 39.342 184.672 43.4802 174.659 51.5765C164.646 59.6729 156.732 71.4876 151.335 86.4209C145.939 101.354 143.121 119.166 143.121 138.717L250.627 200.729L250.687 51.8164Z"
      fill="url(#SlideCardsAndChart_linear_1)"
    />
    <path
      opacity="0.2"
      d="M167.104 118.087C167.104 147.534 173.4 179.979 185.212 211.285C197.024 242.591 213.812 271.378 233.419 293.988C253.025 316.598 274.67 332.011 295.536 338.308C316.401 344.605 335.528 341.487 350.578 329.312C365.627 317.138 375.82 296.507 380.017 270.059C384.154 243.551 382.056 212.365 373.901 180.459C365.747 148.553 352.017 117.307 334.329 90.7393C316.641 64.1712 295.895 43.4205 274.61 31.186V180.159L167.104 118.087Z"
      fill="url(#SlideCardsAndChart_linear_2)"
    />
    <path
      opacity="0.3"
      d="M197.384 102.974L19.0071 0L19.067 93.0182L181.735 186.936L197.504 210.265L197.384 102.974Z"
      fill="url(#SlideCardsAndChart_linear_3)"
    />
    <path
      d="M0 36.2237V10.9751L67.3334 49.8376V75.0863L0 36.2237Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M151.515 99.1353L112.422 76.5854V84.7418L151.515 107.292V99.1353Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M151.575 115.508L76.207 72.0278V80.1842L151.575 123.665V115.508Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M151.575 131.821L60.2583 79.1646V87.3209L151.575 140.037V131.821Z"
      fill="white"
    />
    <path
      d="M161.168 113.229C161.168 120.905 165.725 129.722 171.301 132.96C176.878 136.199 181.434 132.6 181.434 124.924C181.434 117.247 176.878 108.431 171.301 105.193C165.665 101.954 161.168 105.552 161.168 113.229Z"
      fill="#F2994A"
    />
    <path
      opacity="0.3"
      d="M269.154 246.849L447.591 349.883L447.651 442.901L284.983 348.983L269.274 354.141L269.154 246.849Z"
      fill="url(#SlideCardsAndChart_linear_4)"
    />
    <path
      d="M395.667 366.255V341.007L463 379.869V405.118L395.667 366.255Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M315.082 296.027L354.175 318.577V326.733L315.082 304.183V296.027Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M315.082 312.34L385.653 353.062V361.218L315.082 320.556V312.34Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M315.082 328.712L406.399 381.429V389.585L315.082 336.869V328.712Z"
      fill="white"
    />
    <path
      d="M305.429 298.966C305.429 306.642 300.872 310.241 295.296 307.002C289.719 303.764 285.163 294.947 285.163 287.271C285.163 279.594 289.719 275.996 295.296 279.235C300.872 282.473 305.429 291.289 305.429 298.966Z"
      fill="#F2994A"
    />
    <path
      opacity="0.3"
      d="M143.66 200.79L244.151 258.784V445L143.66 387.006V200.79Z"
      fill="url(#SlideCardsAndChart_linear_5)"
    />
    <path
      opacity="0.15"
      d="M153.553 311.32L234.258 357.919V366.076L153.553 319.477V311.32Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M159.19 330.512L228.562 370.574V378.73L159.19 338.668V330.512Z"
      fill="white"
    />
    <path
      d="M188.51 363.797L118.598 323.435V344.245L188.51 384.607V363.797Z"
      fill="#F2C94C"
    />
    <path
      d="M205.358 274.017C205.358 283.013 200.142 287.271 193.726 283.553C187.311 279.834 182.094 269.459 182.094 260.403C182.094 251.407 187.311 247.149 193.726 250.867C200.142 254.645 205.358 265.021 205.358 274.017Z"
      fill="#F2C94C"
    />
    <path
      d="M193.726 290.809C183.353 284.752 174.779 290.449 173.28 303.464C173.04 305.743 174.719 308.741 176.818 309.941L210.634 329.732C212.733 330.931 214.412 329.972 214.172 327.393C212.673 312.52 204.099 296.867 193.726 290.809Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.2"
      d="M87.7194 185.557L62.0571 170.744V162.107L87.7194 176.921C89.8179 178.12 92.8758 180.579 95.454 184.357C98.2721 188.376 100.251 193.473 100.251 198.991C100.251 205.108 98.6918 208.587 95.7538 209.546C92.9957 210.446 89.698 208.886 87.7194 207.747L77.1667 201.63L77.1067 201.57C76.2073 201.03 74.7683 200.49 73.6291 200.85C72.6698 201.15 71.6505 202.049 71.6505 205.468C71.6505 208.886 72.7297 210.925 73.5692 212.185C74.6484 213.744 76.0874 214.824 76.9868 215.303L77.1067 215.363L109.364 234.015V242.651L77.3466 224.12C75.1281 222.98 71.9503 220.521 69.1922 216.683C66.1943 212.425 64.1557 207.087 64.1557 201.15C64.1557 195.213 66.1943 192.214 69.1322 191.374C71.8903 190.595 75.0681 191.734 77.2866 193.054L87.7194 199.111C89.2183 200.01 90.3576 200.37 91.137 200.13C91.7366 199.95 92.8159 199.111 92.8159 194.733C92.8159 191.854 91.9165 189.995 91.0171 188.795C89.9378 187.176 88.5588 186.037 87.7194 185.557Z"
      fill="white"
    />
    <path
      d="M66.7338 168.884C66.7338 176.621 62.1769 180.279 56.6008 177.101C51.0246 173.862 46.4678 164.986 46.4678 157.19C46.4678 149.453 51.0246 145.795 56.6008 148.973C62.1769 152.212 66.7338 161.148 66.7338 168.884Z"
      fill="#F2994A"
    />
    <path
      d="M122.495 245.649C122.495 253.386 117.938 257.044 112.362 253.866C106.786 250.627 102.229 241.751 102.229 233.955C102.229 226.218 106.786 222.56 112.362 225.738C117.938 228.977 122.495 237.913 122.495 245.649Z"
      fill="#F2C94C"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndChart_linear_0"
        x1="428.54"
        y1="98.7416"
        x2="229.497"
        y2="197.456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndChart_linear_1"
        x1="296.371"
        y1="85.84"
        x2="175.939"
        y2="142.536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndChart_linear_2"
        x1="352.5"
        y1="127.317"
        x2="264.383"
        y2="218.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndChart_linear_3"
        x1="158.496"
        y1="65.732"
        x2="66.353"
        y2="137.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndChart_linear_4"
        x1="345.817"
        y1="249.88"
        x2="368.894"
        y2="437.012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndChart_linear_5"
        x1="254.914"
        y1="217.202"
        x2="165.683"
        y2="371.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
