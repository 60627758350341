import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareRounded70Icon: FC = props => (
  <SVG {...props} width="436" height="436" viewBox="0 0 436 436" fill="none">
    <rect
      x="35.5161"
      y="35.5161"
      width="365.367"
      height="365.367"
      rx="70"
      stroke="currentColor"
      strokeWidth="70"
    />
  </SVG>
)
