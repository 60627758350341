import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideAnalysisOnChart: FC = props => (
  <SVG {...props} width="484" height="604" viewBox="0 0 484 604" fill="none">
    <path
      opacity="0.3"
      d="M67.187 259.273C29.4222 217.93 24.1212 257.322 21.6061 257.907C21.6061 257.907 20.484 327.224 21.6061 361.887L389.735 569.779V381.398C347.869 286.964 320.164 221.002 292.924 251.472C245.718 304.174 222.734 339.568 166.59 266.439C133.314 223.098 112.536 308.903 67.187 259.273Z"
      fill="url(#SlideAnalysisOnChart_linear_0)"
    />
    <path
      opacity="0.3"
      d="M58.4103 293.216C10.8001 251.837 4.11708 291.216 0.897532 291.85C0.897532 291.85 -0.517117 301.365 0.897532 336.06L465 604L483.816 509.036C465 293.216 340.365 326.642 297.437 342.403C255.388 357.823 239.973 325.617 186.753 279.7C142.997 241.932 115.582 342.891 58.4103 293.216Z"
      fill="url(#SlideAnalysisOnChart_linear_1)"
    />
    <path
      d="M309.338 251.629C310.994 248.256 310.826 243.282 308.93 240.502C307.033 237.723 303.018 237.129 301.363 240.502C299.708 243.875 299.875 248.85 301.772 251.629C303.624 254.387 307.683 255.002 309.338 251.629Z"
      fill="#F2994A"
    />
    <path
      d="M175.059 278.151C176.714 274.778 176.547 269.803 174.65 267.024C172.754 264.244 168.739 263.651 167.083 267.024C165.428 270.397 165.595 275.371 167.492 278.15C169.345 280.908 173.403 281.523 175.059 278.151Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.07"
      d="M280.413 0L370.463 51.968V229.001L280.413 177.033V0Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M352.901 67.1926L262.851 15.2246L262.802 192.257L301.73 214.704L307.876 226.805L313.974 221.828L352.901 244.225V67.1926Z"
      fill="url(#SlideAnalysisOnChart_linear_2)"
    />
    <path
      opacity="0.1"
      d="M341.096 122.381C341.096 128.041 340.267 133.214 338.706 137.556C337.145 141.899 334.852 145.315 331.974 147.657C329.096 149.999 325.633 151.171 321.876 151.171C318.12 151.171 314.023 149.951 309.974 147.56V104.375L341.096 122.381Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M309.974 147.609C304.511 144.437 299.145 139.362 294.413 132.823C289.681 126.285 285.73 118.575 282.998 110.426C280.267 102.277 278.803 93.9816 278.803 86.4181C278.803 78.8547 280.267 72.2184 282.998 67.2412L309.974 104.424V147.609Z"
      fill="white"
    />
    <path
      d="M341.096 122.382C341.096 112.866 338.853 102.326 334.657 92.3718C330.462 82.4174 324.608 73.6341 318.023 67.3394C311.389 61.0446 304.364 57.6289 298.023 57.6289C291.681 57.6289 286.413 60.9959 282.949 67.2418L309.925 104.425L341.096 122.382Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M274.608 149.463L329.486 181.132V187.768L274.608 156.099V149.463Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M274.608 162.442L341.145 200.845V207.481L274.608 169.079V162.442Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M274.608 175.423L317.73 200.309V206.945L274.608 182.059V175.423Z"
      fill="white"
    />
    <path
      opacity="0.07"
      d="M145.338 26.4473L235.388 78.4153V255.448L145.338 203.48V26.4473Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M218.071 93.7375L128.021 41.7695L127.972 218.851L166.948 241.297L173.046 253.35L179.143 248.373L218.071 270.77V93.7375Z"
      fill="url(#SlideAnalysisOnChart_linear_3)"
    />
    <path
      opacity="0.1"
      d="M206.265 148.975C206.265 156.538 204.802 163.174 202.07 168.151C199.338 173.129 195.387 176.3 190.655 177.374L175.094 130.969L206.265 148.975Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M190.704 177.374C184.753 178.692 177.875 176.593 171.046 171.47C164.265 166.297 157.924 158.343 153.094 148.828L175.143 131.018L190.704 177.374Z"
      fill="white"
    />
    <path
      d="M206.265 148.975C206.265 141.851 204.997 134.141 202.607 126.48C200.168 118.819 196.704 111.451 192.46 105.059C188.216 98.6663 183.289 93.3963 178.216 89.7366C173.094 86.0769 167.972 84.1738 163.24 84.1738C158.509 84.1738 154.314 86.0281 151.094 89.639C147.826 93.2499 145.631 98.4711 144.606 104.863C143.631 111.256 143.874 118.575 145.338 126.236C146.801 133.897 149.484 141.607 153.143 148.731L175.192 130.921L206.265 148.975Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M139.777 176.057L202.07 212.019V218.656L139.777 182.693V176.057Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M139.777 188.988L204.461 226.366V233.003L139.777 195.625V188.988Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M139.777 201.968L182.9 226.854V233.49L139.777 208.604V201.968Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="SlideAnalysisOnChart_linear_0"
        x1="272.669"
        y1="145.623"
        x2="211.738"
        y2="325.81"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideAnalysisOnChart_linear_1"
        x1="293.088"
        y1="219.869"
        x2="219.495"
        y2="408.925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideAnalysisOnChart_linear_2"
        x1="307.852"
        y1="44.8452"
        x2="307.852"
        y2="263.625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="SlideAnalysisOnChart_linear_3"
        x1="173.021"
        y1="70.0602"
        x2="173.021"
        y2="295.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </SVG>
)
