import React, { FC } from "react"

import { SVG } from "./SVG"

export const SmoothwebLogo: FC = props => (
  <SVG {...props} width="43" height="43" viewBox="0 0 43 43" fill="none">
    <mask
      id="SmoothwebLogoMask0"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="22"
      width="40"
      height="10"
    >
      <path
        d="M3.91113 22.6302C5.39527 23.9051 38.6153 21.0812 40.1463 22.5501L40.9954 24.0102C41.241 24.4654 41.4492 24.9601 41.6188 25.4391C41.786 25.9109 41.9187 26.376 42.0208 26.8658C42.1228 27.3556 42.1693 27.8483 42.2029 28.3489C42.2381 28.8666 42.219 29.4502 42.1597 29.9859C42.0929 30.5833 42.0005 31.2716 41.842 31.9349C29.0622 28.3647 18.8674 31.9687 11.5823 27.7519C11.5823 27.7519 10.2608 27.1549 9.62285 26.8111C8.94964 26.4478 8.15655 25.9876 7.53021 25.5717C6.96029 25.1933 6.36754 24.8091 5.82209 24.354C5.26378 23.8876 5.19243 23.8634 4.55904 23.281C4.26537 23.0111 4.27034 23.0419 3.91113 22.6302Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91113 22.6302C5.39527 23.9051 38.6153 21.0812 40.1463 22.5501L40.9954 24.0102C41.241 24.4654 41.4492 24.9601 41.6188 25.4391C41.786 25.9109 41.9187 26.376 42.0208 26.8658C42.1228 27.3556 42.1693 27.8483 42.2029 28.3489C42.2381 28.8666 42.219 29.4502 42.1597 29.9859C42.0929 30.5833 42.0005 31.2716 41.842 31.9349C29.0622 28.3647 18.8674 31.9687 11.5823 27.7519C11.5823 27.7519 10.2608 27.1549 9.62285 26.8111C8.94964 26.4478 8.15655 25.9876 7.53021 25.5717C6.96029 25.1933 6.36754 24.8091 5.82209 24.354C5.26378 23.8876 5.19243 23.8634 4.55904 23.281C4.26537 23.0111 4.27034 23.0419 3.91113 22.6302Z"
        fill="url(#SmoothwebLogoPaint0linear)"
      />
    </g>
    <mask
      id="SmoothwebLogoMask1"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="11"
      width="39"
      height="10"
    >
      <path
        d="M2.09472 20.4455C1.82386 20.0571 1.53101 19.5777 1.31864 19.181C0.994686 18.5752 0.720092 17.9391 0.51228 17.2832C0.404848 16.9453 0.0427326 15.3872 0.0033271 14.0426C-0.0335896 12.7798 0.248885 11.7164 0.248885 11.7164C10.0044 14.3497 23.4023 13.4498 30.6624 15.2487C30.6624 15.2487 33.0346 16.4139 33.9634 16.9587C34.7548 17.423 35.5097 17.9524 36.234 18.5168C36.9619 19.0842 37.6774 19.7125 38.2835 20.3186C25.9985 19.5894 3.32417 21.4917 2.09472 20.4455Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09472 20.4455C1.82386 20.0571 1.53101 19.5777 1.31864 19.181C0.994686 18.5752 0.720092 17.9391 0.51228 17.2832C0.404848 16.9453 0.0427326 15.3872 0.0033271 14.0426C-0.0335896 12.7798 0.248885 11.7164 0.248885 11.7164C10.0044 14.3497 23.4023 13.4498 30.6624 15.2487C30.6624 15.2487 33.0346 16.4139 33.9634 16.9587C34.7548 17.423 35.5097 17.9524 36.234 18.5168C36.9619 19.0842 37.6774 19.7125 38.2835 20.3186C25.9985 19.5894 3.32417 21.4917 2.09472 20.4455Z"
        fill="url(#SmoothwebLogoPaint1linear)"
      />
    </g>
    <mask
      id="SmoothwebLogoMask2"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="2"
      width="33"
      height="26"
    >
      <path
        d="M33.1384 2.66199C33.9667 3.19806 34.6565 3.8159 35.1717 4.513C40.6441 11.9237 9.44701 16.443 11.5824 27.7519C11.5824 27.7519 7.49208 26.1608 4.17373 22.9243C4.0862 22.8388 3.99744 22.7178 3.91116 22.6302C3.68385 21.2477 3.66477 19.8539 4.03809 18.5085C4.38486 17.2578 5.23726 16.2515 6.11496 15.3192C9.39059 11.8391 13.6061 9.44238 17.9242 7.43909C22.759 5.19634 27.8867 3.55725 33.1384 2.66199Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1384 2.66199C33.9667 3.19806 34.6565 3.8159 35.1717 4.513C40.6441 11.9237 9.44701 16.443 11.5824 27.7519C11.5824 27.7519 7.49208 26.1608 4.17373 22.9243C4.0862 22.8388 3.99744 22.7178 3.91116 22.6302C3.68385 21.2477 3.66477 19.8539 4.03809 18.5085C4.38486 17.2578 5.23726 16.2515 6.11496 15.3192C9.39059 11.8391 13.6061 9.44238 17.9242 7.43909C22.759 5.19634 27.8867 3.55725 33.1384 2.66199Z"
        fill="url(#SmoothwebLogoPaint2linear)"
      />
    </g>
    <mask
      id="SmoothwebLogoMask3"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="21"
    >
      <path
        d="M2.09469 20.4454C0.25964 17.783 -0.720519 14.3893 0.637518 10.2751C3.77834 0.760081 21.2988 -2.00706 30.5309 1.39544C23.647 2.81593 17.0103 5.46793 11.0986 9.20709C8.24568 11.0118 5.45867 13.0718 3.50872 15.8139C2.53437 17.1844 2.11294 18.7947 2.09469 20.4329V20.4454Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask3)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09469 20.4454C0.25964 17.783 -0.720519 14.3893 0.637518 10.2751C3.77834 0.760081 21.2988 -2.00706 30.5309 1.39544C23.647 2.81593 17.0103 5.46793 11.0986 9.20709C8.24568 11.0118 5.45867 13.0718 3.50872 15.8139C2.53437 17.1844 2.11294 18.7947 2.09469 20.4329V20.4454Z"
        fill="url(#SmoothwebLogoPaint3linear)"
      />
    </g>
    <mask
      id="SmoothwebLogoMask4"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="22"
      width="32"
      height="21"
    >
      <path
        d="M40.1463 22.5501C41.985 25.2171 42.9652 28.6109 41.6072 32.7255C38.4543 42.2771 20.8102 45.0289 11.6084 41.564C18.0232 40.2273 24.2497 37.8964 29.8851 34.5632C33.1678 32.6216 36.4007 30.383 38.6368 27.3239C39.6564 25.9288 40.128 24.258 40.1463 22.5501Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask4)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1463 22.5501C41.985 25.2171 42.9652 28.6109 41.6072 32.7255C38.4543 42.2771 20.8102 45.0289 11.6084 41.564C18.0232 40.2273 24.2497 37.8964 29.8851 34.5632C33.1678 32.6216 36.4007 30.383 38.6368 27.3239C39.6564 25.9288 40.128 24.258 40.1463 22.5501Z"
        fill="url(#SmoothwebLogoPaint4linear)"
      />
    </g>
    <mask
      id="SmoothwebLogoMask5"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="15"
      width="33"
      height="26"
    >
      <path
        d="M9.12296 40.3479C8.28549 39.8094 7.59112 39.1888 7.07305 38.4875C1.60025 31.0768 32.7978 26.5575 30.6624 15.2487C30.6624 15.2487 34.9335 16.9453 38.2834 20.3186C38.5261 21.7103 38.5821 23.1375 38.2067 24.4921C37.8442 25.7995 36.8955 26.8954 35.9253 27.8954C32.402 31.5257 27.841 34.0304 23.192 36.0704C18.6856 38.0483 13.9487 39.5205 9.12296 40.3479Z"
        fill="white"
      />
    </mask>
    <g mask="url(#SmoothwebLogoMask5)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12296 40.3479C8.28549 39.8094 7.59112 39.1888 7.07305 38.4875C1.60025 31.0768 32.7978 26.5575 30.6624 15.2487C30.6624 15.2487 34.9335 16.9453 38.2834 20.3186C38.5261 21.7103 38.5821 23.1375 38.2067 24.4921C37.8442 25.7995 36.8955 26.8954 35.9253 27.8954C32.402 31.5257 27.841 34.0304 23.192 36.0704C18.6856 38.0483 13.9487 39.5205 9.12296 40.3479Z"
        fill="url(#SmoothwebLogoPaint5linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="SmoothwebLogoPaint0linear"
        x1="7.88777"
        y1="27.0694"
        x2="40.9678"
        y2="28.9559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#674EDD" />
        <stop offset="1" stopColor="#4661CF" />
      </linearGradient>
      <linearGradient
        id="SmoothwebLogoPaint1linear"
        x1="0.0033271"
        y1="24.8546"
        x2="34.2714"
        y2="24.8546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#753AD9" />
        <stop offset="1" stopColor="#524DCC" />
      </linearGradient>
      <linearGradient
        id="SmoothwebLogoPaint2linear"
        x1="17.6844"
        y1="10.7382"
        x2="22.5218"
        y2="15.4358"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A574FF" />
        <stop offset="1" stopColor="#8F7EFF" />
      </linearGradient>
      <linearGradient
        id="SmoothwebLogoPaint3linear"
        x1="12.528"
        y1="9.15151"
        x2="9.53977"
        y2="5.67739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A872FF" />
        <stop offset="1" stopColor="#AA72FF" />
      </linearGradient>
      <linearGradient
        id="SmoothwebLogoPaint4linear"
        x1="19.1419"
        y1="37.2801"
        x2="38.4112"
        y2="24.7322"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EAAFF" />
        <stop offset="1" stopColor="#5982EC" />
      </linearGradient>
      <linearGradient
        id="SmoothwebLogoPaint5linear"
        x1="34.3367"
        y1="23.5353"
        x2="12.7056"
        y2="38.8562"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617EF0" />
        <stop offset="1" stopColor="#ACABFF" />
      </linearGradient>
    </defs>
  </SVG>
)
