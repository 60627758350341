import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndPalette: FC = props => (
  <SVG {...props} width="459" height="461" viewBox="0 0 459 461" fill="none">
    <path
      opacity="0.3"
      d="M182.09 245.496L0 140.283V235.213L166.054 331.122L182.151 354.931L182.09 245.496Z"
      fill="url(#SlideCardsAndPalette_linear_0)"
    />
    <path
      d="M145.121 255.9C145.121 263.735 149.773 272.732 155.465 276.037C161.157 279.342 165.809 275.67 165.809 267.835C165.809 260.001 161.157 251.004 155.465 247.699C149.773 244.455 145.121 248.127 145.121 255.9Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M135.267 241.578L95.3599 218.504V226.828L135.267 249.902V241.578Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M135.328 258.226L16.0972 189.37V197.694L135.266 266.55L135.328 258.226Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M135.328 274.935L42.1101 221.074V229.398L135.328 283.259V274.935Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M117.333 0L193.413 43.9456L193.474 208.772L117.333 164.827V0Z"
      fill="url(#SlideCardsAndPalette_linear_1)"
    />
    <path
      d="M93.5237 45.2305L153.384 79.8116V99.581L93.5237 64.9999V45.2305Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M117.333 140.345L193.413 184.29V208.711L117.333 164.766V140.345Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M126.698 29.9297L157.913 47.9241V56.2481L126.698 38.2536V29.9297Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M126.759 46.6387L181.968 78.5268V86.8507L126.759 54.9626V46.6387Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M159.138 137.834V146.158L126.759 127.429V119.105L159.138 137.834Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M278.062 296.174L201.982 252.229L202.043 417.055L278.123 461L278.062 296.174Z"
      fill="url(#SlideCardsAndPalette_linear_2)"
    />
    <path
      d="M301.871 368.886L242.011 334.305V354.074L301.871 388.655V368.886Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M278.123 436.518L202.043 392.572V416.993L278.123 460.939V436.518Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M213.489 283.382L244.704 301.376V309.7L213.489 291.706V283.382Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M213.489 300.091L268.697 331.979V340.303L213.489 308.415V300.091Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M236.319 385.778V394.102L268.697 412.831V404.507L236.319 385.778Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M433.343 191.329C437.016 193.41 439.953 198.551 439.953 202.774V221.442L452.317 228.542C455.99 230.623 458.928 235.764 458.928 239.987C458.928 244.21 455.99 245.924 452.317 243.843L439.953 236.743V255.411C439.953 259.634 437.016 261.348 433.343 259.267C429.671 257.186 426.733 252.045 426.733 247.821V229.154L414.369 221.993C410.697 219.912 407.759 214.77 407.759 210.486C407.759 206.263 410.697 204.549 414.369 206.63L426.733 213.73V195.062C426.733 190.9 429.671 189.186 433.343 191.329Z"
      fill="url(#SlideCardsAndPalette_linear_3)"
    />
    <path
      d="M318.629 56.4517C314.916 68.832 307.49 84.9265 314.916 99.1638C322.342 113.401 335.338 100.402 355.141 107.211C374.943 114.02 379.222 156.678 366.898 191.397C354.575 226.117 336.575 250.204 293.837 254.57C251.098 258.935 190.1 186.613 223.841 90.9294C247.008 25.2285 288.306 14.9773 306.16 17.7868C329.542 21.4663 322.342 44.0714 318.629 56.4517Z"
      fill="url(#SlideCardsAndPalette_linear_4)"
    />
    <path
      d="M346.281 59.5249C342.568 71.9053 335.142 87.9997 342.568 102.237C349.994 116.474 362.99 103.475 382.793 110.284C402.595 117.093 406.874 159.751 394.551 194.471C382.227 229.19 364.227 253.277 321.489 257.643C278.75 262.008 217.752 189.686 251.493 94.0026C274.66 28.3017 315.958 18.0505 333.812 20.8601C357.194 24.5395 349.994 47.1446 346.281 59.5249Z"
      fill="url(#SlideCardsAndPalette_linear_5)"
      fillOpacity="0.2"
    />
    <path
      d="M306.842 105.453C304.02 100.646 295.555 93.0941 286.384 108.888C281.6 117.127 278.153 126.054 282.857 132.921C287.56 139.788 302.718 129.956 308.253 121.249C312.274 114.922 309.663 110.261 306.842 105.453Z"
      fill="#4B239E"
    />
    <ellipse
      rx="10.5206"
      ry="12.38"
      transform="matrix(0.951352 0.308106 -0.307947 0.951403 243.131 91.7356)"
      fill="url(#SlideCardsAndPalette_linear_6)"
    />
    <ellipse
      rx="10.5206"
      ry="12.38"
      transform="matrix(0.951352 0.308106 -0.307947 0.951403 233.229 131.353)"
      fill="url(#SlideCardsAndPalette_linear_7)"
    />
    <ellipse
      rx="10.5205"
      ry="12.38"
      transform="matrix(0.951352 0.308107 -0.307947 0.951404 235.705 169.732)"
      fill="url(#SlideCardsAndPalette_linear_8)"
    />
    <ellipse
      rx="10.5206"
      ry="12.38"
      transform="matrix(0.951352 0.308107 -0.307947 0.951404 250.557 205.635)"
      fill="url(#SlideCardsAndPalette_linear_9)"
    />
    <ellipse
      rx="10.5206"
      ry="12.38"
      transform="matrix(0.951352 0.308106 -0.307947 0.951403 277.786 229.158)"
      fill="#F2C94C"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndPalette_linear_0"
        x1="142.392"
        y1="207.454"
        x2="48.343"
        y2="281.057"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_1"
        x1="191.065"
        y1="42.6049"
        x2="125.303"
        y2="156.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_2"
        x1="275.753"
        y1="294.837"
        x2="209.992"
        y2="408.672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_3"
        x1="446.12"
        y1="215.179"
        x2="414.073"
        y2="240.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_4"
        x1="401.554"
        y1="30.4528"
        x2="209.037"
        y2="232.197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.39" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_5"
        x1="429.206"
        y1="33.526"
        x2="236.689"
        y2="235.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.39" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_6"
        x1="19.487"
        y1="-6.1392"
        x2="8.61437"
        y2="28.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.14" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_7"
        x1="19.487"
        y1="-6.1392"
        x2="8.61437"
        y2="28.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.14" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_8"
        x1="19.487"
        y1="-6.1392"
        x2="8.61432"
        y2="28.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.14" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPalette_linear_9"
        x1="19.487"
        y1="-6.1392"
        x2="8.61432"
        y2="28.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.14" />
      </linearGradient>
    </defs>
  </SVG>
)
