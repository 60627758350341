import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareRounded20Icon: FC = props => (
  <SVG {...props} width="88" height="88" viewBox="0 0 88 88" fill="none">
    <rect
      x="10.1062"
      y="10.4277"
      width="67.3532"
      height="67.3532"
      rx="20"
      stroke="currentColor"
      strokeWidth="20"
    />
  </SVG>
)
