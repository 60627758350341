import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorTabletDotPattern: FC = props => (
  <SVG
    {...props}
    width="91"
    height="176"
    viewBox="0 0 91 176"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="2.83871"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="2.83871"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="2.83871"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="2.83871" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="2.83871"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="2.83871"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="2.83871"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
    <circle
      cx="2.83871"
      cy="156.129"
      r="2.83871"
      fill="white"
      fillOpacity="0.07"
    />
    <circle
      cx="2.83871"
      cy="173.161"
      r="2.83871"
      fill="white"
      fillOpacity="0.05"
    />
    <circle
      cx="19.8709"
      cy="19.8709"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="19.8709"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="19.8709"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="19.8709"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="19.8709" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="19.8709"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="19.8709"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="19.8709"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
    <circle
      cx="19.8709"
      cy="156.129"
      r="2.83871"
      fill="white"
      fillOpacity="0.07"
    />
    <circle
      cx="19.8709"
      cy="173.161"
      r="2.83871"
      fill="white"
      fillOpacity="0.05"
    />
    <circle
      cx="36.9032"
      cy="19.8709"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="36.9032"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="36.9032"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="36.9032"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="36.9032" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="36.9032"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="36.9032"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="36.9032"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
    <circle
      cx="36.9032"
      cy="156.129"
      r="2.83871"
      fill="white"
      fillOpacity="0.07"
    />
    <circle
      cx="53.9354"
      cy="19.8709"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="53.9354"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="53.9354"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="53.9354"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="53.9354" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="53.9354"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="53.9354"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="53.9354"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
    <circle
      cx="53.9354"
      cy="156.129"
      r="2.83871"
      fill="white"
      fillOpacity="0.07"
    />
    <circle
      cx="70.9676"
      cy="2.83871"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="70.9676"
      cy="19.8709"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="70.9676"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="70.9676"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="70.9676"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="70.9676" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="70.9676"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="70.9676"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="70.9676"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
    <circle
      cx="70.9676"
      cy="156.129"
      r="2.83871"
      fill="white"
      fillOpacity="0.07"
    />
    <circle
      cx="87.9998"
      cy="2.83871"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="87.9998"
      cy="19.8709"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="87.9998"
      cy="36.9033"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="87.9998"
      cy="53.9355"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle
      cx="87.9998"
      cy="70.9677"
      r="2.83871"
      fill="white"
      fillOpacity="0.2"
    />
    <circle cx="87.9998" cy="88" r="2.83871" fill="white" fillOpacity="0.2" />
    <circle
      cx="87.9998"
      cy="105.032"
      r="2.83871"
      fill="white"
      fillOpacity="0.16"
    />
    <circle
      cx="87.9998"
      cy="122.065"
      r="2.83871"
      fill="white"
      fillOpacity="0.12"
    />
    <circle
      cx="87.9998"
      cy="139.097"
      r="2.83871"
      fill="white"
      fillOpacity="0.1"
    />
  </SVG>
)
