import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareSmallSeb: FC = props => (
  <SVG {...props} width="66" height="66" viewBox="0 0 66 66" fill="none">
    <rect
      x="19.8682"
      y="10"
      width="38.127"
      height="38.127"
      rx="10"
      transform="rotate(15 19.8682 10)"
      stroke="currentColor"
      strokeWidth="15"
    />
  </SVG>
)
