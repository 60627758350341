import React, { FC } from "react"

import { SVG } from "./SVG"

export const TriangleIcon: FC = props => (
  <SVG {...props} width="457" height="420" viewBox="0 0 457 420" fill="none">
    <path
      d="M175.672 58.5001C199.151 17.8334 257.849 17.8334 281.328 58.5001L421.191 300.75C444.669 341.417 415.321 392.25 368.363 392.25L88.6369 392.25C41.679 392.25 12.3304 341.417 35.8093 300.75L175.672 58.5001Z"
      stroke="currentColor"
      strokeWidth="55"
    />
  </SVG>
)
