import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndRightTriangle: FC = props => (
  <SVG {...props} width="465" height="414" viewBox="0 0 465 414" fill="none">
    <path
      opacity="0.1"
      d="M216.762 3.18799L435.614 332.266L216.817 206.066L216.762 3.18799Z"
      fill="url(#SlideCardsAndRightTriangle_linear_0)"
    />
    <path
      opacity="0.4"
      d="M425.709 355.242C427.965 356.561 428.956 353.868 427.195 351.23L195.74 3.24308C194.199 0.879567 191.888 0.824602 191.888 3.13315L191.943 217.608C191.943 219.148 192.989 220.961 194.309 221.731L425.709 355.242ZM226.172 102.291C226.172 99.9823 228.483 100.037 230.024 102.401L331.498 254.765C333.259 257.403 332.269 260.097 330.012 258.778L228.593 200.239C227.272 199.47 226.227 197.656 226.227 196.117L226.172 102.291Z"
      fill="url(#SlideCardsAndRightTriangle_linear_1)"
    />
    <path
      opacity="0.3"
      d="M301.232 10.3882L465 104.874V190.125L315.705 103.939L301.287 108.666L301.232 10.3882Z"
      fill="url(#SlideCardsAndRightTriangle_linear_2)"
    />
    <path
      d="M334.47 58.1534C334.47 65.189 330.287 68.4869 325.17 65.5187C320.052 62.5506 315.87 54.4707 315.87 47.4351C315.87 40.3995 320.052 37.1016 325.17 40.0697C330.342 43.0379 334.47 51.1178 334.47 58.1534Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M343.33 55.4053L379.209 76.1273V83.6026L343.33 62.8806V55.4053Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M343.33 70.4106L450.472 132.247V139.722L343.33 77.886V70.4106Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M343.33 85.4165L427.14 133.786V141.261L343.33 92.8918V85.4165Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M163.713 220.467L0 125.981V211.232L149.295 297.363L163.768 318.745L163.713 220.467Z"
      fill="url(#SlideCardsAndRightTriangle_linear_3)"
    />
    <path
      d="M130.475 229.811C130.475 236.846 134.658 244.926 139.775 247.894C144.893 250.862 149.075 247.564 149.075 240.529C149.075 233.493 144.893 225.413 139.775 222.445C134.658 219.532 130.475 222.83 130.475 229.811Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M121.615 216.949L85.7358 196.227V203.702L121.615 224.424V216.949Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M121.67 231.899L14.4727 170.063V177.538L121.615 239.374L121.67 231.899Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M121.67 246.905L37.8604 198.535V206.01L121.67 254.38V246.905Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M105.492 0L173.893 39.4652L173.948 187.487L105.492 148.022V0Z"
      fill="url(#SlideCardsAndRightTriangle_linear_4)"
    />
    <path
      d="M84.085 40.6196L137.904 71.6751V89.429L84.085 58.3735V40.6196Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M105.492 126.036L173.893 165.501V187.432L105.492 147.967V126.036Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M113.911 26.8779L141.976 43.0378V50.5131L113.911 34.3532V26.8779Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M113.967 41.8838L163.603 70.5208V77.9961L113.967 49.3591V41.8838Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M143.077 123.782V131.258L113.967 114.438V106.963L143.077 123.782Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M249.999 265.978L181.598 226.513L181.653 374.535L250.054 414L249.999 265.978Z"
      fill="url(#SlideCardsAndRightTriangle_linear_5)"
    />
    <path
      d="M271.406 331.277L217.587 300.221V317.975L271.406 349.031V331.277Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M250.055 392.014L181.653 352.548V374.48L250.055 413.945V392.014Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M191.943 254.49L220.008 270.65V278.125L191.943 261.966V254.49Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M191.943 269.496L241.58 298.133V305.608L191.943 276.971V269.496Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M212.469 346.447V353.923L241.58 370.742V363.267L212.469 346.447Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M389.609 171.822C392.911 173.691 395.552 178.308 395.552 182.101V198.865L406.668 205.241C409.97 207.11 412.612 211.727 412.612 215.52C412.612 219.312 409.97 220.851 406.668 218.983L395.552 212.607V229.371C395.552 233.164 392.911 234.703 389.609 232.834C386.308 230.965 383.666 226.348 383.666 222.555V205.791L372.55 199.36C369.248 197.491 366.607 192.874 366.607 189.026C366.607 185.234 369.248 183.695 372.55 185.564L383.666 191.94V175.175C383.666 171.437 386.308 169.898 389.609 171.822Z"
      fill="url(#SlideCardsAndRightTriangle_linear_6)"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_0"
        x1="376.181"
        y1="81.3209"
        x2="264.331"
        y2="275.159"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_1"
        x1="364.204"
        y1="84.794"
        x2="250.935"
        y2="280.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.8963" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_2"
        x1="371.608"
        y1="13.1753"
        x2="392.621"
        y2="184.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_3"
        x1="128.021"
        y1="186.304"
        x2="43.5379"
        y2="252.497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_4"
        x1="171.782"
        y1="38.2612"
        x2="112.759"
        y2="140.549"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_5"
        x1="247.924"
        y1="264.777"
        x2="188.901"
        y2="367.065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndRightTriangle_linear_6"
        x1="401.096"
        y1="193.241"
        x2="372.31"
        y2="215.985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
