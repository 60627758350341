import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorShapeWorm: FC = props => (
  <SVG
    {...props}
    width="928"
    height="764"
    viewBox="0 0 928 764"
    fill="none"
    preserveAspectRatio="none"
  >
    <path
      d="M446.523 499.4C303.026 464.273 540.1 325.29 501.6 151.462C463.1 -22.3667 122.198 -45.5446 42.6004 76.6333C-56.8969 229.356 27.5337 644.995 196.029 728.992C364.525 812.989 920.51 730.519 927.009 627.686C933.509 524.853 590.019 534.526 446.523 499.4Z"
      fill="url(#VectorShapeWormPaint0linear)"
      fillOpacity="0.1"
    />
    <defs>
      <linearGradient
        id="VectorShapeWormPaint0linear"
        x1="498.1"
        y1="-32.3668"
        x2="498.1"
        y2="763.633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
