import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareRounded90Icon: FC = props => (
  <SVG
    {...props}
    width="1106"
    height="1139"
    viewBox="0 0 1106 1139"
    fill="none"
  >
    <rect
      x="658.559"
      y="83"
      width="747.33"
      height="638.988"
      rx="90"
      transform="rotate(61.8791 658.559 83)"
      stroke="currentColor"
      strokeWidth="140"
    />
  </SVG>
)
