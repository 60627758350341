import React, { FC } from "react"

import { SVG } from "./SVG"

export const ProcessPointerIcon: FC = props => (
  <SVG {...props} width="124" height="62" viewBox="0 0 124 62" fill="none">
    <mask
      id="ProcessPointerIconMask01"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="124"
      height="62"
      mask-type="alpha"
      style={{ maskType: "alpha" }}
    >
      <path
        d="M113 62C113 33.8335 90.1665 11 62 11C33.8335 11 11 33.8335 11 62H0C0 27.7583 27.7583 0 62 0C96.2417 0 124 27.7583 124 62H113Z"
        fill="#68BFDA"
      />
    </mask>
    <g mask="url(#ProcessPointerIconMask01)">
      <path
        d="M113 62C113 33.8335 90.1665 11 62 11C33.8335 11 11 33.8335 11 62H0C0 27.7583 27.7583 0 62 0C96.2417 0 124 27.7583 124 62H113Z"
        fill="#6136B9"
      />
      <path
        d="M5.05156 21.9328L21.8494 22.2196L26.4701 38.3719L-4.58161 78.8292L-26.0002 62.3901L5.05156 21.9328Z"
        fill="url(#ProcessPointerIconLinearGradient01)"
      />
      <path
        d="M35.7636 -1.58623L50.6954 6.11384L47.6808 22.6415L1.91737 45.1517L-9.99981 20.924L35.7636 -1.58623Z"
        fill="url(#ProcessPointerIconLinearGradient02)"
      />
      <path
        d="M111.155 13.0622L108.68 29.6792L92.0626 32.1541L56.0002 -3.90833L75.0921 -23.0002L111.155 13.0622Z"
        fill="url(#ProcessPointerIconLinearGradient03)"
      />
      <path
        d="M131.803 68.4557L122.904 82.7054L106.679 78.345L87.9995 30.8891L113.123 20.9998L131.803 68.4557Z"
        fill="url(#ProcessPointerIconLinearGradient04)"
      />
      <path
        d="M82.4629 -4.81627L87.0011 11.3595L72.8498 20.4144L25.1918 2.25633L34.8049 -22.9744L82.4629 -4.81627Z"
        fill="url(#ProcessPointerIconLinearGradient05)"
      />
    </g>
    <defs>
      <linearGradient
        id="ProcessPointerIconLinearGradient01"
        x1="4.74713"
        y1="22.3294"
        x2="4.64874"
        y2="57.6638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.68" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerIconLinearGradient02"
        x1="35.3149"
        y1="-1.36554"
        x2="19.5704"
        y2="30.2673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.68" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerIconLinearGradient03"
        x1="110.801"
        y1="12.7087"
        x2="75.7812"
        y2="8.00331"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.68" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerIconLinearGradient04"
        x1="131.62"
        y1="67.9904"
        x2="101.386"
        y2="49.7037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.68" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="ProcessPointerIconLinearGradient05"
        x1="81.9957"
        y1="-4.99429"
        x2="48.1146"
        y2="5.0354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.68" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
