import React, { FC } from "react"

import { SVG } from "./SVG"

export const SmileOnMessageIcon: FC = props => (
  <SVG {...props} width="54" height="51" viewBox="0 0 54 51" fill="none">
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H48C51.3137 0 54 2.68629 54 6V32C54 35.3137 51.3137 38 48 38H17.8441C16.972 38 16.1238 38.285 15.4286 38.8116L3.20771 48.0699C1.89048 49.0678 0 48.1283 0 46.4757V6Z"
      fill="#492CA9"
    />
    <circle cx="27" cy="19" r="11" stroke="#F2F4FC" strokeWidth="2" />
    <path
      d="M33 20C33 22.7614 30.3137 25 27 25C23.6863 25 21 22.7614 21 20"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SVG>
)
