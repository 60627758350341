import React, { FC } from "react"

import { SVG } from "./SVG"

export const PuzzleComplementationIcon: FC = props => (
  <SVG {...props} width="66" height="50" viewBox="0 0 66 50" fill="none">
    <path
      d="M51.5406 0.415019C53.0204 -0.439335 54.9126 0.0676783 55.7669 1.54746L65.0486 17.6238C65.9029 19.1036 65.3959 20.9957 63.9161 21.8501L47.8398 31.1318C46.36 31.9861 44.4678 31.4791 43.6135 29.9993L42.3412 27.7957C41.8806 26.9979 40.5073 27.1635 39.9677 27.9101C39.4722 28.5956 38.8274 29.1901 38.0475 29.6404C35.0879 31.3491 31.3035 30.335 29.5948 27.3755C27.8861 24.4159 28.9001 20.6315 31.8597 18.9228C32.6396 18.4725 33.4768 18.2113 34.3182 18.125C35.2346 18.0309 36.0647 16.9245 35.6041 16.1267L34.3318 13.923C33.4775 12.4432 33.9845 10.551 35.4643 9.69668L51.5406 0.415019Z"
      fill="#492CA9"
    />
    <path
      d="M13.8525 18.728C14.0352 19.5304 14.9726 20.1243 15.6853 19.7129L17.6238 18.5937C19.1036 17.7392 20.9957 18.246 21.8501 19.7265L23.1224 21.9296C23.583 22.7275 22.7529 23.8339 21.8365 23.9276C20.9951 24.0146 20.1579 24.2753 19.378 24.7255C16.4184 26.4345 15.4044 30.2187 17.1131 33.1786C18.8218 36.1386 22.6062 37.1523 25.5657 35.4433C26.3456 34.9931 26.9905 34.3983 27.4859 33.7128C28.0256 32.9667 29.3989 32.8007 29.8595 33.5985L31.1318 35.8026C31.9861 37.2821 31.4791 39.1747 29.9993 40.0292L13.923 49.3105C12.4432 50.165 10.551 49.6581 9.69669 48.1776L0.415015 32.1015C-0.439325 30.622 0.0676634 28.7294 1.54746 27.8749L3.90639 26.5131C4.61927 26.1015 4.5735 24.9939 3.96451 24.4401C2.75761 23.3426 1.99998 21.7597 1.99998 19.9999C1.99998 16.6864 4.68625 13.9999 7.99998 13.9999C10.8606 13.9999 13.2506 16.0841 13.8525 18.728Z"
      fill="#492CA9"
    />
  </SVG>
)
