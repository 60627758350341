import React, { FC } from "react"

import { SVG } from "./SVG"

export const PlayTriangleIcon: FC = props => (
  <SVG {...props} width="21" height="25" viewBox="0 0 21 25" fill="none">
    <path
      d="M4.01516 0.838349C2.68371 -0.00893784 0.941406 0.947491 0.941406 2.52567V22.69C0.941406 24.2681 2.68371 25.2246 4.01516 24.3773L19.8585 14.2951C21.0935 13.5092 21.0935 11.7064 19.8585 10.9205L4.01516 0.838349Z"
      fill="currentColor"
    />
  </SVG>
)
