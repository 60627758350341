import React, { FC } from "react"

import { SVG } from "./SVG"

export const DesktopAndMobileIcon: FC = props => (
  <SVG {...props} width="62" height="58" viewBox="0 0 62 58" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V40C0 42.2091 1.79086 44 4 44H26.6737C26.4462 46.3567 26.1719 48.7461 24.5 50C22.3256 51.6308 20.5945 52.3751 19.4672 52.7148C18.674 52.9538 18 53.6716 18 54.5C18 55.3284 18.6716 56 19.5 56H42.5C43.3284 56 44 55.3284 44 54.5C44 53.6716 43.326 52.9538 42.5328 52.7148C41.4055 52.3751 39.6744 51.6308 37.5 50C35.8281 48.7461 35.5538 46.3567 35.3263 44H58C60.2091 44 62 42.2091 62 40V4C62 1.79086 60.2091 0 58 0H4Z"
      fill="url(#playTriangleIconFill0)"
    />
    <rect
      x="35"
      y="21"
      width="20"
      height="36"
      rx="5"
      fill="#492CA9"
      stroke="white"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="playTriangleIconFill0"
        x1="0"
        y1="0"
        x2="53"
        y2="56"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#775BD0" />
        <stop offset="1" stopColor="#382088" />
      </linearGradient>
    </defs>
  </SVG>
)
