import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCloudAndServer: FC = props => (
  <SVG {...props} width="464" height="477" viewBox="0 0 464 477" fill="none">
    <path
      opacity="0.07"
      d="M421.717 208.578C421.717 211.098 421.661 213.617 421.493 215.969C445.511 234.278 464 273.528 464 308.075C464 345.701 442.048 363.45 414.958 347.772C414.902 347.716 414.846 347.716 414.79 347.66L244.596 249.284C244.54 249.228 244.484 249.228 244.372 249.172C213.93 231.534 189.242 182.99 189.242 140.717C189.242 101.131 210.914 81.0299 238.675 93.18C242.082 66.0242 260.235 54.322 282.131 67.0321C292.632 73.1351 302.295 83.8294 309.836 96.7074C320.784 80.414 339.44 76.2706 360.554 88.4767C394.347 107.962 421.717 161.769 421.717 208.578Z"
      fill="url(#SlideCloudAndServer_linear_0)"
    />
    <path
      opacity="0.4"
      d="M393.788 235.454C393.788 237.974 393.732 240.493 393.565 242.845C417.583 261.154 436.072 300.404 436.072 334.951C436.072 372.577 414.12 390.326 387.03 374.648C386.974 374.592 386.918 374.592 386.862 374.536L216.667 276.16C216.611 276.104 216.556 276.104 216.444 276.048C186.002 258.41 161.313 209.866 161.313 167.593C161.313 128.007 182.986 107.906 210.746 120.056C214.154 92.9002 232.307 81.198 254.203 93.908C264.704 100.011 274.367 110.705 281.908 123.583C292.856 107.29 311.512 103.147 332.625 115.353C366.419 134.838 393.788 188.645 393.788 235.454Z"
      fill="url(#SlideCloudAndServer_linear_1)"
    />
    <path
      opacity="0.2"
      d="M124.337 283.382L98.4194 268.377V259.586L124.337 274.592C126.459 275.823 129.532 278.287 132.157 282.094C135.005 286.182 137.016 291.333 137.016 296.932C137.016 303.091 135.452 306.619 132.436 307.626C129.643 308.578 126.348 306.955 124.281 305.779L113.612 299.62L113.557 299.564C112.663 299.004 111.211 298.5 110.038 298.836C109.088 299.116 108.027 300.068 108.027 303.483C108.027 306.899 109.088 309.026 109.982 310.258C111.099 311.826 112.551 312.946 113.445 313.394L113.557 313.45L146.121 332.319V341.109L113.724 322.352C111.49 321.176 108.306 318.713 105.513 314.793C102.497 310.538 100.374 305.107 100.43 299.06C100.43 293.069 102.497 289.989 105.513 289.149C108.306 288.365 111.49 289.485 113.724 290.885L124.281 296.988C125.789 297.884 126.962 298.276 127.688 297.996C128.247 297.828 129.364 296.988 129.364 292.509C129.364 289.653 128.414 287.75 127.577 286.462C126.571 285.006 125.175 283.886 124.337 283.382Z"
      fill="white"
    />
    <path
      d="M104.005 266.193C104.005 273.416 99.7594 276.831 94.5648 273.864C89.3701 270.84 85.125 262.553 85.125 255.275C85.125 248.052 89.3701 244.636 94.5648 247.604C99.7594 250.627 104.005 258.97 104.005 266.193Z"
      fill="#F2994A"
    />
    <path
      d="M159.302 344.021C159.302 351.244 155.057 354.659 149.863 351.692C144.668 348.668 140.423 340.382 140.423 333.103C140.423 325.88 144.668 322.464 149.863 325.432C155.057 328.455 159.302 336.798 159.302 344.021Z"
      fill="#F2994A"
    />
    <path
      opacity="0.07"
      d="M203.932 158.018L371.446 254.938V343.797L203.876 246.876L203.932 158.018Z"
      fill="url(#SlideCloudAndServer_linear_2)"
    />
    <path
      opacity="0.3"
      d="M181.534 169.216L349.047 266.137V354.995L181.534 258.074V169.216Z"
      fill="url(#SlideCloudAndServer_linear_3)"
    />
    <path
      d="M196.559 222.128C196.559 229.294 200.804 237.525 205.999 240.549C211.193 243.572 215.438 240.213 215.438 233.046C215.438 225.879 211.193 217.648 205.999 214.625C200.804 211.601 196.559 214.961 196.559 222.128Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M220.187 235.957C220.187 239.26 222.141 243.124 224.599 244.523C227.001 245.923 228.956 244.355 228.956 241.052C228.956 237.749 227.001 233.885 224.543 232.485C222.142 231.086 220.187 232.653 220.187 235.957Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M233.648 243.74C233.648 247.043 235.603 250.907 238.06 252.307C240.462 253.706 242.417 252.139 242.417 248.835C242.417 245.532 240.462 241.668 238.005 240.269C235.603 238.869 233.648 240.437 233.648 243.74Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M306.764 258.858V309.418L300.173 305.611V255.051L306.764 258.858Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M321.175 267.257V317.817L314.584 314.009V263.449L321.175 267.257Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M335.642 275.655V326.215L329.051 322.408V271.848L335.642 275.655Z"
      fill="white"
    />
    <path
      opacity="0.07"
      d="M203.876 279.463L371.39 376.384V465.242L203.876 368.321V279.463Z"
      fill="url(#SlideCloudAndServer_linear_4)"
    />
    <path
      opacity="0.3"
      d="M181.534 291.221L349.047 388.142V477L181.534 380.079V291.221Z"
      fill="url(#SlideCloudAndServer_linear_5)"
    />
    <path
      d="M196.559 344.132C196.559 351.299 200.804 359.53 205.999 362.554C211.193 365.577 215.438 362.218 215.438 355.051C215.438 347.884 211.193 339.653 205.999 336.63C200.804 333.606 196.559 336.966 196.559 344.132Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M220.187 358.018C220.187 361.322 222.141 365.185 224.599 366.585C227.001 367.985 228.956 366.417 228.956 363.114C228.956 359.81 227.001 355.947 224.543 354.547C222.142 353.147 220.187 354.659 220.187 358.018Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M233.592 365.801C233.592 369.104 235.547 372.968 238.005 374.368C240.407 375.767 242.362 374.2 242.362 370.896C242.362 367.593 240.407 363.729 237.949 362.329C235.547 360.874 233.592 362.441 233.592 365.801Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M306.764 382.598V433.158L300.173 429.351V378.791L306.764 382.598Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M321.175 390.997V441.557L314.584 437.75V387.189L321.175 390.997Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M335.642 399.396V449.956L329.051 446.149V395.589L335.642 399.396Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M32.7878 37.5801L137.184 97.9947V291.893L32.7878 231.422V37.5801Z"
      fill="url(#SlideCloudAndServer_linear_6)"
    />
    <path
      d="M97.2462 107.793C97.2462 117.479 91.6606 122.071 84.7902 118.039C77.9199 114.008 72.3342 102.922 72.3342 93.235C72.3342 83.5485 77.9199 78.9572 84.7902 82.9886C91.6606 87.02 97.2462 98.1063 97.2462 107.793Z"
      fill="#F2C94C"
    />
    <path
      d="M84.7903 125.823C73.6748 119.328 64.5144 125.431 62.8945 139.429C62.6152 141.837 64.4585 145.084 66.6928 146.372L102.944 167.593C105.178 168.88 107.021 167.872 106.742 165.073C105.066 149.115 95.8499 132.318 84.7903 125.823Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M53.7341 163.337L115.958 199.339V206.954L53.7341 170.952V163.337Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M60.0457 182.094L109.367 210.593V218.208L60.0457 189.709V182.094Z"
      fill="white"
    />
    <path
      d="M72.6135 214.121L0 172.071V193.74L72.6135 235.789V214.121Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.2"
      d="M122.81 0L138.338 30.5713C140.46 34.7706 140.125 38.9699 137.556 39.9778C134.986 40.9856 131.188 38.354 129.066 34.1547L128.898 33.7628V67.6935C128.898 71.5569 126.217 73.1246 122.866 71.2209C119.514 69.2612 116.833 64.614 116.833 60.7506V26.8198L116.666 26.9878C114.543 28.7235 110.689 26.9318 108.175 22.9564C105.606 18.9811 105.271 14.3898 107.393 12.654L122.81 0Z"
      fill="url(#SlideCloudAndServer_linear_7)"
    />
    <defs>
      <linearGradient
        id="SlideCloudAndServer_linear_0"
        x1="454.008"
        y1="101.654"
        x2="280.778"
        y2="265.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_1"
        x1="426.073"
        y1="128.53"
        x2="252.844"
        y2="292.447"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_2"
        x1="284.237"
        y1="165.123"
        x2="290.508"
        y2="322.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_3"
        x1="261.893"
        y1="176.321"
        x2="268.164"
        y2="333.498"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_4"
        x1="284.225"
        y1="286.587"
        x2="290.496"
        y2="443.764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_5"
        x1="261.881"
        y1="298.345"
        x2="268.153"
        y2="455.522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_6"
        x1="148.372"
        y1="54.6213"
        x2="55.3647"
        y2="215.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCloudAndServer_linear_7"
        x1="133.86"
        y1="30.8377"
        x2="105.497"
        y2="46.6107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
