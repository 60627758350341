import React, { FC } from "react"

import { SVG } from "./SVG"

export const CalendarIcon: FC = props => (
  <SVG {...props} width="24" height="26" viewBox="0 0 24 26" fill="none">
    <path
      d="M21.3395 25.722H2.65351C1.19095 25.722 0 24.5134 0 23.0291V4.05819C0 2.57389 1.19095 1.36523 2.65351 1.36523H21.3395C22.8021 1.36523 23.993 2.57389 23.993 4.05819V23.022C24 24.5134 22.809 25.722 21.3395 25.722ZM2.65351 2.85661C1.99884 2.85661 1.46953 3.39379 1.46953 4.05819V23.022C1.46953 23.6864 1.99884 24.2236 2.65351 24.2236H21.3395C21.9942 24.2236 22.5235 23.6864 22.5235 23.022V4.05819C22.5235 3.39379 21.9942 2.85661 21.3395 2.85661H2.65351Z"
      fill="currentColor"
    />
    <path
      d="M17.3557 5.39467C17.0423 5.39467 16.7916 5.14022 16.7916 4.82216V0.849863C16.7916 0.531797 17.0423 0.277344 17.3557 0.277344C17.6691 0.277344 17.9198 0.531797 17.9198 0.849863V4.81509C17.9198 5.13315 17.6691 5.39467 17.3557 5.39467ZM6.58147 5.39467C6.26806 5.39467 6.01733 5.14022 6.01733 4.82216V0.849863C6.01733 0.531797 6.26806 0.277344 6.58147 0.277344C6.89487 0.277344 7.1456 0.531797 7.1456 0.849863V4.81509C7.15256 5.13315 6.89487 5.39467 6.58147 5.39467ZM12.6755 18.2163C10.3354 18.2163 8.43405 16.2867 8.43405 13.9118C8.43405 11.5369 10.3354 9.60728 12.6755 9.60728C13.7341 9.60728 14.744 10.0031 15.524 10.724C15.6215 10.8089 15.6285 10.9644 15.5379 11.0563C15.4544 11.1552 15.3011 11.1623 15.2106 11.0704C14.5141 10.4272 13.6157 10.0738 12.6755 10.0738C10.5931 10.0738 8.90068 11.7913 8.90068 13.9047C8.90068 16.0181 10.5931 17.7356 12.6755 17.7356C13.6157 17.7356 14.5141 17.3822 15.2106 16.739C15.3081 16.6542 15.4544 16.6613 15.5379 16.7532C15.6215 16.8521 15.6145 17.0006 15.524 17.0854C14.744 17.8205 13.7341 18.2163 12.6755 18.2163Z"
      fill="currentColor"
    />
  </SVG>
)
