import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndCurlyBraces: FC = props => (
  <SVG {...props} width="464" height="573" viewBox="0 0 464 573" fill="none">
    <path
      opacity="0.07"
      d="M463.936 288.199C455.862 283.487 450.013 283.359 446.389 287.817C442.765 292.211 440.984 299.663 440.984 310.171V366.787C440.984 377.55 439.967 386.466 437.996 393.535C436.025 400.858 432.655 406.081 428.014 409.138C423.5 412.258 417.396 413.086 409.64 411.621C402.074 410.22 392.601 406.272 381.284 399.712L375.879 396.592V352.012L382.619 355.897C390.693 360.609 396.098 361.183 398.831 357.744C401.692 354.432 403.155 347.49 403.155 336.982V287.117C403.155 274.125 404.426 263.871 406.97 256.293C409.513 248.714 414.535 243.938 422.101 241.773C414.535 230.882 409.513 220.247 406.97 209.803C404.426 199.358 403.218 187.64 403.218 174.648V124.783C403.218 114.275 401.756 105.741 398.895 99.054C396.225 92.4944 390.82 86.8901 382.682 82.1774L375.943 78.2926V33.7129L381.347 36.8335C392.664 43.3931 402.138 50.3347 409.703 57.7222C417.46 65.1734 423.564 73.1341 428.078 81.4768C432.783 89.947 436.089 98.9903 438.06 108.607C440.031 117.968 441.048 128.094 441.048 138.794V195.41C441.048 205.918 442.828 215.471 446.452 224.004C450.076 232.602 455.925 239.225 464 243.874L463.936 288.199Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_0)"
    />
    <path
      opacity="0.07"
      d="M258.894 124.21C266.968 128.923 272.818 129.05 276.442 124.592C280.066 120.198 281.846 112.747 281.846 102.238V45.6223C281.846 34.8595 282.863 25.9436 284.834 18.8745C286.805 11.5507 290.048 6.26481 294.562 3.14423C299.267 0.0873397 305.37 -0.740569 312.936 0.660507C320.693 2.12527 330.23 6.13744 341.547 12.697L346.951 15.8176V60.3973L340.212 56.5125C332.137 51.7998 326.606 51.1629 323.745 54.4746C321.074 57.9136 319.675 64.8553 319.675 75.3633V125.229C319.675 138.221 318.404 148.474 315.861 156.053C313.318 163.567 308.295 168.407 300.729 170.573C308.295 181.463 313.318 192.098 315.861 202.543C318.404 212.987 319.612 224.705 319.612 237.697V287.563C319.612 298.071 320.947 306.605 323.681 313.164C326.542 319.851 332.073 325.519 340.148 330.168L346.887 334.053V378.633L341.483 375.512C330.166 368.952 320.629 361.947 312.873 354.496C305.307 347.108 299.203 339.211 294.498 330.741C289.984 322.398 286.742 313.419 284.771 303.739C282.8 294.377 281.782 284.251 281.782 273.552V216.936C281.782 206.428 280.002 196.875 276.378 188.341C272.754 179.743 266.905 173.12 258.83 168.471L258.894 124.21Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_1)"
    />
    <path
      opacity="0.4"
      d="M438.505 307.305C430.43 302.592 424.581 302.465 420.957 306.923C417.333 311.317 415.553 318.768 415.553 329.276V385.892C415.553 396.655 414.535 405.571 412.564 412.64C410.594 419.964 407.224 425.186 402.583 428.243C398.068 431.364 391.965 432.191 384.208 430.727C376.642 429.326 367.169 425.377 355.852 418.818L350.448 415.697V371.117L357.187 375.002C365.262 379.715 370.666 380.288 373.4 376.849C376.261 373.537 377.723 366.596 377.723 356.088V306.222C377.723 293.23 378.995 282.977 381.538 275.398C384.081 267.82 389.104 263.043 396.67 260.878C389.104 249.988 384.081 239.352 381.538 228.908C378.995 218.464 377.787 206.746 377.787 193.754V143.888C377.787 133.38 376.324 124.846 373.463 118.159C370.793 111.6 365.389 105.996 357.251 101.283L350.511 97.398V52.8184L355.916 55.9389C367.233 62.4985 376.706 69.4402 384.272 76.8277C392.028 84.2789 398.132 92.2395 402.646 100.582C407.351 109.052 410.657 118.096 412.628 127.712C414.599 137.074 415.616 147.2 415.616 157.899V214.515C415.616 225.023 417.396 234.576 421.02 243.11C424.645 251.707 430.494 258.331 438.568 262.98L438.505 307.305Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_2)"
    />
    <path
      opacity="0.4"
      d="M233.462 143.315C241.537 148.028 247.386 148.155 251.01 143.697C254.634 139.303 256.414 131.852 256.414 121.344V64.7278C256.414 53.965 257.432 45.049 259.403 37.98C261.373 30.6562 264.616 25.3703 269.13 22.2497C273.835 19.1928 279.939 18.3649 287.504 19.766C295.261 21.2307 304.798 25.2429 316.115 31.8025L321.519 34.9231V79.5027L314.78 75.6179C306.705 70.9052 301.174 70.2684 298.313 73.58C295.643 77.019 294.244 83.9607 294.244 94.4688V144.334C294.244 157.326 292.972 167.579 290.429 175.158C287.886 182.673 282.863 187.513 275.297 189.678C282.863 200.568 287.886 211.204 290.429 221.648C292.972 232.093 294.18 243.811 294.18 256.803V306.668C294.18 317.176 295.515 325.71 298.249 332.27C301.11 338.956 306.642 344.625 314.716 349.274L321.456 353.158V397.738L316.051 394.617C304.734 388.058 295.198 381.052 287.441 373.601C279.875 366.214 273.771 358.317 269.067 349.847C264.552 341.504 261.31 332.524 259.339 322.844C257.368 313.482 256.351 303.356 256.351 292.657V236.041C256.351 225.533 254.571 215.98 250.947 207.446C247.322 198.849 241.473 192.226 233.399 187.577L233.462 143.315Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_3)"
    />
    <path
      opacity="0.15"
      d="M92.3168 7.79297L200.973 70.6503L200.91 331.314L92.2532 268.457L92.3168 7.79297Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_4)"
    />
    <path
      d="M71.908 47.8516L158.312 97.8445V121.854L71.908 71.8609V47.8516Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M105.732 152.04L188.766 200.059V237.378L105.732 189.36V152.04Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M105.668 106.888L188.83 154.97V163.631L105.668 115.613V106.888Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M105.668 123.828L177.576 165.415V174.076L105.668 132.489V123.828Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M92.3169 7.79297L200.973 70.6503V105.614L92.3169 42.7562V7.79297Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_5)"
    />
    <path
      opacity="0.2"
      d="M119.465 41.1638C119.465 46.4497 116.35 48.9334 112.535 46.7681C108.72 44.5391 105.668 38.489 105.668 33.2032C105.668 27.9173 108.784 25.4336 112.599 27.5989C116.35 29.8279 119.465 35.878 119.465 41.1638Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M225.833 249.479L334.49 312.336L334.426 573L225.769 510.142L225.833 249.479Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_6)"
    />
    <path
      d="M267.223 325.328L353.627 375.257V399.203L267.159 349.274L267.223 325.328Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M239.248 350.102L322.346 398.184V435.44L239.248 387.357V350.102Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M239.121 403.343L322.282 451.425V460.086L239.121 412.068V403.343Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M239.121 419.009L311.029 460.595V469.256L239.121 427.67V419.009Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M225.833 249.479L334.49 312.336V347.299L225.833 284.442V249.479Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_7)"
    />
    <path
      opacity="0.2"
      d="M252.981 282.914C252.981 288.2 249.866 290.683 246.051 288.518C242.236 286.289 239.185 280.239 239.185 274.953C239.185 269.667 242.3 267.184 246.115 269.349C249.866 271.514 252.981 277.628 252.981 282.914Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M207.013 285.589L17.8657 176.241L17.9293 275.017L190.419 374.748L207.141 399.522L207.013 285.589Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_8)"
    />
    <path
      opacity="0.15"
      d="M158.376 281.512L116.922 257.566V266.228L158.376 290.173V281.512Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M158.376 298.899L55.759 239.544V248.205L158.439 307.56L158.376 298.899Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M158.439 316.285L34.5237 244.639V253.3L158.439 324.946V316.285Z"
      fill="white"
    />
    <path
      d="M168.612 296.478C168.612 304.63 173.444 313.992 179.357 317.431C185.27 320.87 190.102 317.049 190.102 308.897C190.102 300.745 185.27 291.383 179.357 287.944C173.38 284.505 168.612 288.327 168.612 296.478Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M36.558 106.888L11.5078 92.3676V83.8975L36.558 98.4177C38.5925 99.6277 41.5807 101.984 44.1239 105.678C46.8578 109.626 48.8287 114.594 48.8287 120.007C48.8287 125.993 47.3028 129.369 44.4418 130.388C41.7079 131.279 38.5289 129.751 36.558 128.605L26.2582 122.618L26.1946 122.554C25.3045 122.045 23.9057 121.535 22.8249 121.854C21.9348 122.109 20.8539 123.064 20.8539 126.376C20.8539 129.687 21.8712 131.725 22.7613 132.935C23.8421 134.464 25.2409 135.546 26.131 135.992L26.2582 136.056L57.7934 154.27V162.74L26.4489 144.59C24.2872 143.443 21.1718 141.087 18.5015 137.329C15.5769 133.19 13.5423 127.968 13.5423 122.109C13.5423 116.313 15.5769 113.32 18.4379 112.492C21.1082 111.728 24.2236 112.874 26.3853 114.148L36.6215 120.071C38.0839 120.899 39.2283 121.281 39.9276 121.026C40.4999 120.835 41.5171 120.007 41.5171 115.74C41.5171 112.938 40.627 111.155 39.7369 109.881C38.7196 108.48 37.3845 107.334 36.558 106.888Z"
      fill="white"
    />
    <path
      d="M18.9466 93.2591C18.9466 100.519 14.6868 103.895 9.47328 100.901C4.2598 97.9081 0 89.5654 0 82.3052C0 75.0451 4.2598 71.6698 9.47328 74.663C14.6868 77.7199 18.9466 86.0627 18.9466 93.2591Z"
      fill="#F2C94C"
    />
    <path
      d="M69.1741 163.313C69.1741 170.573 64.9143 173.948 59.7008 170.955C54.4873 167.962 50.2275 159.619 50.2275 152.359C50.2275 145.099 54.4873 141.724 59.7008 144.717C64.9143 147.774 69.1741 156.116 69.1741 163.313Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.2"
      d="M136.759 384.747C140.573 386.976 143.625 392.262 143.625 396.72V416.144L156.468 423.531C160.283 425.76 163.335 431.046 163.335 435.504C163.335 439.898 160.219 441.681 156.468 439.516L143.625 432.129V451.553C143.625 455.947 140.51 457.73 136.759 455.565C132.944 453.336 129.892 448.05 129.892 443.592V424.168L117.049 416.78C113.234 414.551 110.183 409.266 110.183 404.808C110.183 400.413 113.298 398.63 117.049 400.795L129.892 408.183V388.759C129.892 384.301 132.944 382.518 136.759 384.747Z"
      fill="url(#SlideCardsAndCurlyBraces_linear_9)"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_0"
        x1="503.936"
        y1="46.5891"
        x2="346.738"
        y2="318.583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_1"
        x1="428.54"
        y1="1.83665"
        x2="271.341"
        y2="273.831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_2"
        x1="478.505"
        y1="65.6946"
        x2="321.306"
        y2="337.689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_3"
        x1="403.108"
        y1="20.942"
        x2="245.909"
        y2="292.936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_4"
        x1="202.945"
        y1="71.7669"
        x2="98.8802"
        y2="251.759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_5"
        x1="129.597"
        y1="8.32519"
        x2="162.765"
        y2="102.212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_6"
        x1="336.456"
        y1="313.468"
        x2="232.392"
        y2="493.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_7"
        x1="263.109"
        y1="250.027"
        x2="296.276"
        y2="343.913"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_8"
        x1="165.771"
        y1="246.061"
        x2="67.9573"
        y2="322.518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndCurlyBraces_linear_9"
        x1="150.021"
        y1="409.56"
        x2="116.681"
        y2="435.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
