import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndPerson: FC = props => (
  <SVG {...props} width="463" height="597" viewBox="0 0 463 597" fill="none">
    <path
      opacity="0.5"
      d="M246.182 183.259C205.678 159.586 172.192 181.711 166.268 232.8C165.237 241.637 171.935 253.313 180.113 258.086L312.251 335.364C320.429 340.138 327.126 336.267 326.096 326.204C320.172 268.343 286.622 206.933 246.182 183.259Z"
      fill="url(#SlideCardsAndPerson_linear_0)"
    />
    <path
      opacity="0.5"
      d="M291.323 116.947C291.323 152.232 270.974 168.875 245.86 154.167C220.746 139.46 200.397 99.0146 200.397 63.7299C200.397 28.4451 220.746 11.8026 245.86 26.51C270.974 41.2173 291.323 81.6625 291.323 116.947Z"
      fill="url(#SlideCardsAndPerson_linear_2)"
    />
    <path
      opacity="0.07"
      d="M272.198 162.101C231.693 138.427 198.208 160.553 192.283 211.642C191.253 220.479 197.95 232.155 206.128 236.928L338.267 314.206C346.445 318.98 353.142 315.109 352.112 305.046C346.187 247.184 312.702 185.775 272.198 162.101Z"
      fill="url(#SlideCardsAndPerson_linear_3)"
    />
    <path
      opacity="0.07"
      d="M317.339 95.7891C317.339 131.074 296.99 147.716 271.876 133.009C246.762 118.302 226.413 77.8564 226.413 42.5717C226.413 7.28694 246.762 -9.35558 271.876 5.35177C296.99 20.0591 317.339 60.5043 317.339 95.7891Z"
      fill="url(#SlideCardsAndPerson_linear_4)"
    />
    <path
      opacity="0.2"
      d="M404.851 361.36L290.421 295.177V354.909L404.851 421.092V361.36Z"
      fill="url(#SlideCardsAndPerson_linear_5)"
    />
    <path
      d="M313.668 328.655C313.668 333.558 310.77 335.88 307.164 333.816C303.558 331.752 300.66 326.075 300.66 321.173C300.66 316.27 303.558 313.948 307.164 316.012C310.77 318.076 313.668 323.753 313.668 328.655Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M319.721 326.526L354.108 346.459V353.619L319.656 333.687L319.721 326.526Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M319.721 340.912L393.002 383.292V390.581L319.721 348.201V340.912Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M172.385 292.339L57.9556 226.22V285.953L172.385 352.136V292.339Z"
      fill="url(#SlideCardsAndPerson_linear_6)"
    />
    <path
      d="M81.2021 259.634C81.2021 264.536 78.3044 266.859 74.6982 264.794C71.0921 262.73 68.1943 257.054 68.1943 252.151C68.1943 247.249 71.0921 244.927 74.6982 246.991C78.3044 249.055 81.2021 254.731 81.2021 259.634Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M87.0623 257.828L107.797 269.826V277.115L87.0623 265.117V257.828Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M87.2551 272.213L160.537 314.593V321.882L87.2551 279.502V272.213Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M246.118 358.393L462.936 483.792L463 597L265.307 482.695L246.182 488.953L246.118 358.393Z"
      fill="url(#SlideCardsAndPerson_linear_7)"
    />
    <path
      d="M290.164 421.802C290.164 431.155 284.69 435.477 277.864 431.543C271.039 427.608 265.565 416.835 265.565 407.546C265.565 398.193 271.039 393.871 277.864 397.806C284.626 401.741 290.164 412.513 290.164 421.802Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M301.884 418.189L349.407 445.669V455.603L301.884 428.123V418.189Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M301.884 438.122L443.81 520.173V530.107L301.884 448.055V438.122Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M301.884 458.054L412.836 522.238V532.172L301.884 467.988V458.054Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M216.818 390.903L0 265.568L0.064395 378.776L197.757 493.081L216.882 521.463L216.818 390.903Z"
      fill="url(#SlideCardsAndPerson_linear_8)"
    />
    <path
      d="M172.771 403.353C172.771 412.707 178.309 423.415 185.071 427.349C191.897 431.284 197.37 426.898 197.37 417.545C197.37 408.191 191.832 397.483 185.071 393.548C178.309 389.678 172.771 394.064 172.771 403.353Z"
      fill="#F2994A"
    />
    <path
      opacity="0.15"
      d="M161.052 386.259L113.528 358.78V368.714L161.052 396.193V386.259Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M161.116 406.127L19.1897 324.076V334.01L161.052 416.061L161.116 406.127Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M161.116 426.059L50.1636 361.876V371.81L161.116 435.993V426.059Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M134.235 90.931C138.099 93.1242 141.19 98.5426 141.19 103.058V122.732L154.197 130.215C158.061 132.408 161.152 137.827 161.152 142.278C161.152 146.728 158.061 148.535 154.197 146.341L141.19 138.794V158.469C141.19 162.919 138.099 164.726 134.235 162.532C130.371 160.339 127.28 154.921 127.28 150.47V130.796L114.273 123.313C110.409 121.12 107.318 115.701 107.318 111.25C107.318 106.799 110.409 104.993 114.273 107.186L127.28 114.669V94.9948C127.216 90.4794 130.371 88.6733 134.235 90.931Z"
      fill="url(#SlideCardsAndPerson_linear_9)"
    />
    <path
      opacity="0.2"
      d="M370.078 209.384L387.98 244.604C390.427 249.442 390.04 254.28 387.078 255.441C384.116 256.602 379.737 253.57 377.29 248.732L377.097 248.281V287.372C377.097 291.822 374.006 293.629 370.142 291.435C366.279 289.178 363.188 283.824 363.188 279.373V240.282L362.994 240.476C360.547 242.475 356.104 240.411 353.206 235.831C350.244 231.251 349.858 225.962 352.305 223.962L370.078 209.384Z"
      fill="url(#SlideCardsAndPerson_linear_10)"
    />
    <path
      opacity="0.2"
      d="M216.818 272.348L189.257 256.415V247.126L216.818 263.059C219.072 264.35 222.356 266.994 225.125 271.058C228.152 275.38 230.277 280.863 230.277 286.798C230.277 293.377 228.602 297.119 225.447 298.151C222.485 299.118 218.943 297.441 216.818 296.215L205.485 289.636L205.42 289.571C204.454 288.991 202.909 288.41 201.685 288.797C200.655 289.12 199.56 290.087 199.56 293.764C199.56 297.441 200.719 299.634 201.621 300.989C202.78 302.666 204.325 303.827 205.291 304.343L205.42 304.408L240.065 324.469V333.758L205.613 313.826C203.231 312.6 199.818 309.955 196.856 305.827C193.636 301.247 191.447 295.506 191.447 289.12C191.447 282.734 193.636 279.508 196.791 278.605C199.753 277.767 203.166 278.992 205.549 280.412L216.754 286.927C218.364 287.894 219.587 288.281 220.424 288.023C221.068 287.83 222.227 286.927 222.227 282.218C222.227 279.121 221.261 277.122 220.295 275.832C219.201 274.09 217.784 272.864 216.818 272.348Z"
      fill="white"
    />
    <path
      d="M194.344 254.416C194.344 262.737 189.45 266.672 183.461 263.253C177.473 259.77 172.579 250.223 172.579 241.837C172.579 233.516 177.473 229.581 183.461 233C189.45 236.483 194.344 246.095 194.344 254.416Z"
      fill="#F2994A"
    />
    <path
      d="M254.231 336.983C254.231 345.304 249.337 349.239 243.349 345.82C237.36 342.337 232.466 332.79 232.466 324.405C232.466 316.083 237.36 312.148 243.349 315.567C249.337 319.051 254.231 328.662 254.231 336.983Z"
      fill="#F2C94C"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndPerson_linear_0"
        x1="413.272"
        y1="144.654"
        x2="248.581"
        y2="264.584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_2"
        x1="329.443"
        y1="29.2745"
        x2="164.753"
        y2="149.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_3"
        x1="439.298"
        y1="123.488"
        x2="274.608"
        y2="243.418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_4"
        x1="355.47"
        y1="8.10836"
        x2="190.78"
        y2="128.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_5"
        x1="360.533"
        y1="335.742"
        x2="334.654"
        y2="380.501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_6"
        x1="128.067"
        y1="266.729"
        x2="102.189"
        y2="311.488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_7"
        x1="339.246"
        y1="362.078"
        x2="367.375"
        y2="589.834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_8"
        x1="169.54"
        y1="345.57"
        x2="57.4278"
        y2="433.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_9"
        x1="147.664"
        y1="116.06"
        x2="113.904"
        y2="142.657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndPerson_linear_10"
        x1="382.803"
        y1="244.914"
        x2="350.115"
        y2="263.104"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
