import React, { FC } from "react"

import { SVG } from "./SVG"

export const IconTabletSelection: FC = props => (
  <SVG
    {...props}
    width="219"
    height="303"
    viewBox="0 0 219 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M176.153 17.69L213.362 247.924C213.939 251.491 211.706 254.91 208.209 255.817L56.8417 295.061C52.8088 296.106 48.761 293.419 48.1586 289.297L7.7135 12.5371C7.0769 8.1809 10.5717 4.32547 14.9693 4.5326L169.572 11.8146C172.879 11.9704 175.625 14.4217 176.153 17.69Z"
      stroke="#47C2E9"
    />
    <path
      d="M172.8 11.4704H176.545L177.08 15.7506H173.335L172.8 11.4704Z"
      fill="#1C9FC8"
    />
    <g filter="url(#IconTabletSelectionfilter0d)">
      <path
        d="M173.412 10.859H177.157L177.692 15.1392H173.947L173.412 10.859Z"
        fill="white"
      />
    </g>
    <path
      d="M211.041 252.227L214.729 251.577L215.999 255.699L212.311 256.349L211.041 252.227Z"
      fill="#1C9FC8"
    />
    <g filter="url(#IconTabletSelectionfilter1d)">
      <path
        d="M211.537 251.519L215.225 250.868L216.495 254.991L212.807 255.641L211.537 251.519Z"
        fill="white"
      />
    </g>
    <path
      d="M5.26221 3.06296H9.94365L10.6124 8.41317H5.93098L5.26221 3.06296Z"
      fill="#1C9FC8"
    />
    <g filter="url(#IconTabletSelectionfilter2d)">
      <path
        d="M6.02637 2.29863H10.7078L11.3766 7.64885H6.69514L6.02637 2.29863Z"
        fill="white"
      />
    </g>
    <path
      d="M48.0645 291.669H52.7459L53.4147 297.019H48.7332L48.0645 291.669Z"
      fill="#1C9FC8"
    />
    <g filter="url(#IconTabletSelectionfilter3d)">
      <path
        d="M48.8286 290.905H53.5101L54.1788 296.255H49.4974L48.8286 290.905Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="IconTabletSelectionfilter0d"
        x="167.412"
        y="8.85898"
        width="12.2802"
        height="12.2802"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.277552 0 0 0 0 0.760112 0 0 0 0 0.9125 0 0 0 0.67 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1DropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1DropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="IconTabletSelectionfilter1d"
        x="205.537"
        y="248.868"
        width="12.9584"
        height="12.7726"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.277552 0 0 0 0 0.760112 0 0 0 0 0.9125 0 0 0 0.67 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1DropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1DropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="IconTabletSelectionfilter2d"
        x="0.0263672"
        y="0.29863"
        width="13.3502"
        height="13.3502"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.277552 0 0 0 0 0.760112 0 0 0 0 0.9125 0 0 0 0.67 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1DropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1DropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="IconTabletSelectionfilter3d"
        x="42.8286"
        y="288.905"
        width="13.3502"
        height="13.3502"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.277552 0 0 0 0 0.760112 0 0 0 0 0.9125 0 0 0 0.67 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1DropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1DropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
)
