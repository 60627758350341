import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorShapeWave: FC = props => (
  <SVG {...props} width="880" height="76" viewBox="0 0 880 76" fill="none">
    <path
      d="M571.5 65.8886C441 38.3886 267.5 -7 -43.5 -7L-249.5 -51.8935C137.5 -134.06 904.335 -204.342 878.5 -58.3935C856.5 65.8885 702 93.3886 571.5 65.8886Z"
      fill="url(#vectorShapeWave)"
      fillOpacity="0.7"
    />
    <defs>
      <linearGradient
        id="vectorShapeWave"
        x1="309.5"
        y1="-275.612"
        x2="593"
        y2="169.388"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E83D1" />
        <stop offset="1" stopColor="#9E5DF1" />
      </linearGradient>
    </defs>
  </SVG>
)
