import React from "react"
import styled from "styled-components"

import { SmoothwebLogo, HamburgerMenuIcon, SmoothwebTextIcon } from "./ui/icons"

export const TopNavigation: React.FC = () => {
  return (
    <TopNavigationInner>
      <SmoothwebLogoContainer>
        <StyledSmoothwebLogo />
        <StyledSmoothwebTextIcon />
      </SmoothwebLogoContainer>

      <RightSide>
        <StyledHamburgerMenuIcon />

        <Navlink>Reference</Navlink>
        <Navlink>Služby</Navlink>
        <Navlink>Naše Workflow</Navlink>
        <Navlink>Technologie</Navlink>
        <Navlink>Kontakt</Navlink>
      </RightSide>
    </TopNavigationInner>
  )
}

const TopNavigationInner = styled.header`
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.white};
  overflow: hidden;
  z-index: 100;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    padding: 0 ${props => props.theme.spaces.xl};
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    top: 25px;
    min-height: 43px;
  }
`

const SmoothwebLogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledSmoothwebLogo = styled(SmoothwebLogo)`
  width: auto;
  height: 25px;
  margin-right: ${props => props.theme.spaces.m};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    height: 43px;
    margin-right: ${props => props.theme.spaces.l};
  }
`
const StyledSmoothwebTextIcon = styled(SmoothwebTextIcon)`
  height: 10px;
  width: auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    height: 15.5px;
  }
`

const RightSide = styled.div`
  display: flex;

  & > * {
    margin-left: ${props => props.theme.spaces.xl};
  }
`

const StyledHamburgerMenuIcon = styled(HamburgerMenuIcon)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: none;
  }
`

const Navlink = styled.div`
  display: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: block;
  }
`
