import React, { FC } from "react"

import { SVG } from "./SVG"

export const SlideCardsAndReaction: FC = props => (
  <SVG {...props} width="464" height="544" viewBox="0 0 464 544" fill="none">
    <path
      opacity="0.2"
      d="M153.988 216.593L47.6797 155.128V210.599L153.988 272.064V216.593Z"
      fill="url(#SlideCardsAndReaction_linear_0)"
    />
    <path
      d="M69.2592 186.243C69.2592 190.834 66.5856 192.938 63.2118 191.025C59.9016 189.112 57.1643 183.82 57.1643 179.23C57.1643 174.639 59.8379 172.535 63.2118 174.448C66.5856 176.36 69.2592 181.652 69.2592 186.243Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.15"
      d="M74.8611 182.545L113.629 204.925V213.596L74.8611 191.28V182.545Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M74.925 201.163L143.039 240.503V249.174L74.925 209.834V201.163Z"
      fill="white"
    />
    <path
      opacity="0.07"
      d="M237.506 37.4907L356.673 106.415V363.495L237.506 294.635V37.4907Z"
      fill="url(#SlideCardsAndReaction_linear_1)"
    />
    <path
      opacity="0.3"
      d="M209.942 60.6992L329.109 129.624V386.704L209.942 317.843V60.6992Z"
      fill="url(#SlideCardsAndReaction_linear_2)"
    />
    <path
      opacity="0.15"
      d="M221.465 203.968L317.206 259.312V267.983L221.465 212.64V203.968Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M228.149 225.073L310.458 272.701V281.373L228.149 233.744V225.073Z"
      fill="white"
    />
    <path
      d="M178.814 258.865V230.173L249.156 270.852V299.544L178.814 258.865Z"
      fill="#F2994A"
    />
    <path
      opacity="0.2"
      d="M424.214 252.745C418.74 244.01 411.164 242.862 410.846 242.798C410.082 242.671 409.637 243.372 409.637 244.392V267.473C409.637 275.316 406.963 280.353 401.679 282.521C401.17 282.712 400.852 283.286 400.852 284.051C400.852 295.145 401.043 342.774 401.106 357.63C401.106 360.117 402.825 363.049 404.926 364.261C416.257 370.828 448.786 389.637 448.786 389.637C457.443 394.674 462.982 391.55 463.936 381.221L464 335.505C464 328.555 462.791 321.414 458.971 315.485C456.807 312.105 454.26 309.491 451.587 307.961L429.37 295.145V273.722C429.434 265.305 427.652 258.292 424.214 252.745Z"
      fill="url(#SlideCardsAndReaction_linear_3)"
    />
    <path
      d="M371.124 264.349L395.95 278.695V360.052L371.124 345.706V264.349Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.3"
      d="M189.445 109.54L0 0L0.0636575 98.8917L172.767 198.74L189.509 223.542L189.445 109.54Z"
      fill="url(#SlideCardsAndReaction_linear_4)"
    />
    <path
      opacity="0.15"
      d="M140.747 105.395L99.2424 81.4214V90.0927L140.747 114.066V105.395Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M140.747 122.802L37.9397 63.3774V72.0488L140.747 131.473V122.802Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M140.747 140.208L16.7417 68.478V77.1494L140.747 148.879V140.208Z"
      fill="white"
    />
    <path
      d="M150.932 120.379C150.932 128.54 155.77 137.913 161.69 141.356C167.61 144.799 172.448 140.973 172.448 132.812C172.448 124.651 167.61 115.278 161.69 111.835C155.77 108.392 150.932 112.218 150.932 120.379Z"
      fill="#F2994A"
    />
    <path
      opacity="0.3"
      d="M271.118 335.569L460.563 445.108L460.626 544L287.86 444.152L271.181 449.635L271.118 335.569Z"
      fill="url(#SlideCardsAndReaction_linear_5)"
    />
    <path
      opacity="0.15"
      d="M319.816 387.852L361.32 411.826V420.497L319.816 396.523V387.852Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M319.816 405.194L394.741 448.487V457.159L319.816 413.866V405.194Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M319.816 422.601L416.766 478.646V487.317L319.816 431.272V422.601Z"
      fill="white"
    />
    <path
      d="M309.63 390.976C309.63 399.137 304.792 402.963 298.872 399.52C292.952 396.077 288.114 386.704 288.114 378.543C288.114 370.382 292.952 366.556 298.872 369.999C304.792 373.442 309.566 382.815 309.63 390.976Z"
      fill="#F2C94C"
    />
    <path
      opacity="0.2"
      d="M205.55 318.608C209.369 320.776 212.425 326.132 212.425 330.532V349.978L225.284 357.375C229.103 359.542 232.159 364.898 232.159 369.298C232.159 373.697 229.103 375.482 225.284 373.314L212.425 365.918V385.365C212.425 389.765 209.369 391.55 205.55 389.382C201.731 387.214 198.675 381.858 198.675 377.459V358.012L185.816 350.616C181.997 348.448 178.941 343.092 178.941 338.693C178.941 334.293 181.997 332.508 185.816 334.676L198.675 342.072V322.625C198.611 318.226 201.731 316.377 205.55 318.608Z"
      fill="url(#SlideCardsAndReaction_linear_6)"
    />
    <path
      opacity="0.05"
      d="M269.272 194.468C214.653 123.312 254.185 97.5529 269.272 139.252C284.422 115.278 323.89 187.136 269.272 194.468Z"
      fill="url(#SlideCardsAndReaction_linear_7)"
    />
    <path
      opacity="0.3"
      d="M256.54 207.22C201.922 136.064 241.453 110.305 256.54 152.004C271.691 128.03 311.158 199.888 256.54 207.22Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="SlideCardsAndReaction_linear_0"
        x1="112.796"
        y1="192.814"
        x2="88.7611"
        y2="234.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_1"
        x1="366.302"
        y1="80.4227"
        x2="245.827"
        y2="288.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_2"
        x1="338.757"
        y1="103.632"
        x2="218.282"
        y2="312.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_3"
        x1="408.917"
        y1="308.257"
        x2="488.716"
        y2="338.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_4"
        x1="148.117"
        y1="69.9079"
        x2="50.1873"
        y2="146.461"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_5"
        x1="352.47"
        y1="338.797"
        x2="377.038"
        y2="537.738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_6"
        x1="218.822"
        y1="343.46"
        x2="185.451"
        y2="369.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="SlideCardsAndReaction_linear_7"
        x1="280.945"
        y1="142.125"
        x2="240.084"
        y2="177.006"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SVG>
)
