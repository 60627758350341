import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareBigSeb: FC = props => (
  <SVG {...props} width="339" height="339" viewBox="0 0 339 339" fill="none">
    <rect
      x="205.829"
      y="35"
      width="197.256"
      height="197.256"
      rx="50"
      transform="rotate(60 205.829 35)"
      stroke="currentColor"
      strokeWidth="50"
    />
  </SVG>
)
