import React, { FC } from "react"

import { SVG } from "./SVG"

export const AnalyticsGraphIcon: FC = props => (
  <SVG {...props} width="62" height="44" viewBox="0 0 62 44" fill="none">
    <rect width="62" height="44" rx="4" fill="#492CA9" />
    <rect
      x="8"
      y="30"
      width="6"
      height="6"
      rx="3"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="25"
      y="16"
      width="6"
      height="6"
      rx="3"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="42"
      y="22"
      width="6"
      height="6"
      rx="3"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.3492 28.2407C14.9299 28.6002 14.8813 29.2315 15.2407 29.6508C15.6002 30.0701 16.2315 30.1187 16.6508 29.7593L15.3492 28.2407ZM23.6508 23.7593C24.0701 23.3998 24.1187 22.7685 23.7593 22.3492C23.3998 21.9299 22.7685 21.8813 22.3492 22.2407L23.6508 23.7593ZM16.6508 29.7593L23.6508 23.7593L22.3492 22.2407L15.3492 28.2407L16.6508 29.7593Z"
      fill="#F2F4FC"
    />
    <path
      d="M38.7127 23.9578C39.2416 24.1165 39.7991 23.8163 39.9578 23.2873C40.1165 22.7584 39.8163 22.2009 39.2873 22.0422L38.7127 23.9578ZM34.2873 20.5422C33.7584 20.3835 33.2009 20.6837 33.0422 21.2127C32.8835 21.7416 33.1837 22.2991 33.7127 22.4578L34.2873 20.5422ZM39.2873 22.0422L34.2873 20.5422L33.7127 22.4578L38.7127 23.9578L39.2873 22.0422Z"
      fill="#F2F4FC"
    />
    <path
      d="M47.6679 19.4453C47.3616 19.9048 47.4858 20.5257 47.9453 20.8321C48.4048 21.1384 49.0257 21.0142 49.3321 20.5547L47.6679 19.4453ZM54.3321 13.0547C54.6384 12.5952 54.5142 11.9743 54.0547 11.6679C53.5952 11.3616 52.9743 11.4858 52.6679 11.9453L54.3321 13.0547ZM49.3321 20.5547L54.3321 13.0547L52.6679 11.9453L47.6679 19.4453L49.3321 20.5547Z"
      fill="#F2F4FC"
    />
  </SVG>
)
