import React, { FC } from "react"

import { SVG } from "./SVG"

export const TachometerIcon: FC = props => (
  <SVG {...props} width="60" height="55" viewBox="0 0 60 55" fill="none">
    <circle cx="30" cy="31.4287" r="23" fill="#3D258E" />
    <path
      d="M29.9999 0C13.4314 0 0 13.4314 0 29.9999C0 33.9287 0.75519 37.681 2.12832 41.1197C2.55781 42.1952 3.62501 42.8571 4.78314 42.8571H55.2168C56.3749 42.8571 57.4421 42.1952 57.8716 41.1197C59.2447 37.681 59.9999 33.9287 59.9999 29.9999C59.9999 13.4314 46.5685 0 29.9999 0Z"
      fill="#492CA9"
    />
    <circle cx="30" cy="29.9997" r="5.35713" fill="#F2F4FC" />
    <path
      d="M5.35718 30H11.7857"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M48.2144 30H54.6429"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.65869 17.6777L14.226 20.892"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.6787 8.6582L20.893 14.2255"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M30 5.35742L30 11.786"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M42.3213 8.6582L39.107 14.2255"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M51.3411 17.6787L34.2856 27.8573"
      stroke="#F2F4FC"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SVG>
)
