import React, { FC } from "react"

import { SVG } from "./SVG"

export const SquareMediumSeb: FC = props => (
  <SVG {...props} width="133" height="133" viewBox="0 0 133 133" fill="none">
    <rect
      x="52.2534"
      y="14"
      width="76.5066"
      height="76.5066"
      rx="20"
      transform="rotate(30 52.2534 14)"
      stroke="currentColor"
      strokeWidth="20"
    />
  </SVG>
)
