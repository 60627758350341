import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorShapeTooth: FC = props => (
  <SVG {...props} width="473" height="198" viewBox="0 0 473 198" fill="none">
    <path
      d="M386.54 58.6368C328.606 132.137 308 14.5 0 0.5L234.041 -165.798L414.107 -108.363C414.107 -108.363 444.474 -14.8632 386.54 58.6368Z"
      fill="url(#vectorShapeTooth)"
      fillOpacity="0.2"
    />
    <defs>
      <linearGradient
        id="vectorShapeTooth"
        x1="603.096"
        y1="-469.803"
        x2="726.805"
        y2="168.505"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BC97F9" />
        <stop offset="1" stopColor="#BAC7EA" stopOpacity="0.95" />
      </linearGradient>
    </defs>
  </SVG>
)
