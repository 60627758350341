import React, { FC } from "react"

import { SVG } from "./SVG"

export const SmoothwebTextIcon: FC = props => (
  <SVG {...props} width="125" height="17" viewBox="0 0 125 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.13671 16.136C3.43889 16.136 2.06815 15.7923 1.02453 15.1049C0.52607 14.8012 0.276855 14.3696 0.276855 13.8102C0.276855 13.4905 0.362535 13.2307 0.533863 13.0309C0.705222 12.8311 0.915477 12.7312 1.16472 12.7312C1.42952 12.7312 1.79555 12.8591 2.26285 13.1148C2.76131 13.3546 3.21693 13.5384 3.62968 13.6663C4.04247 13.7942 4.56819 13.8581 5.20681 13.8581C5.86105 13.8581 6.37115 13.7462 6.73721 13.5224C7.10324 13.2987 7.28627 12.9869 7.28627 12.5873C7.28627 12.3156 7.2123 12.0998 7.06431 11.9399C6.91633 11.7801 6.65153 11.6322 6.26992 11.4964C5.8883 11.3605 5.30807 11.2046 4.52923 11.0288C3.14293 10.7411 2.14995 10.3335 1.55022 9.80598C0.950525 9.27846 0.650678 8.55116 0.650678 7.62403C0.650678 6.92069 0.853173 6.28531 1.25816 5.71785C1.66315 5.15039 2.22001 4.7108 2.92876 4.39908C3.63748 4.08739 4.44358 3.93155 5.347 3.93155C6.0012 3.93155 6.63207 4.01547 7.23955 4.18329C7.84704 4.35113 8.39222 4.5949 8.87507 4.91459C9.37353 5.23432 9.62275 5.6579 9.62275 6.18542C9.62275 6.50511 9.5371 6.77285 9.36574 6.98865C9.19441 7.20444 8.98412 7.31234 8.73491 7.31234C8.56355 7.31234 8.3961 7.27636 8.23257 7.20444C8.069 7.13252 7.86262 7.02462 7.61338 6.88075C7.16167 6.64098 6.77226 6.46113 6.44516 6.34126C6.11805 6.22136 5.71306 6.16141 5.23018 6.16141C4.65387 6.16141 4.19824 6.28131 3.86335 6.52108C3.52845 6.76085 3.36101 7.08854 3.36101 7.50416C3.36101 7.90377 3.53233 8.20749 3.87502 8.41528C4.21771 8.62308 4.8797 8.8309 5.86105 9.03869C6.90466 9.26249 7.72243 9.51823 8.31433 9.80598C8.90624 10.0937 9.33069 10.4534 9.5877 10.8849C9.8447 11.3165 9.97322 11.868 9.97322 12.5394C9.97322 13.6264 9.53319 14.4975 8.65311 15.1529C7.77303 15.8083 6.6009 16.136 5.13671 16.136Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0567 3.93155C26.7325 3.93155 28.0885 4.41148 28.7506 5.13085C29.4127 5.85022 29.7632 7.05657 29.7632 8.75098V14.6494C29.7632 15.1129 29.6269 15.4686 29.3543 15.7163C29.0817 15.9641 28.7274 16.088 28.2912 16.088C27.8707 16.088 27.5241 15.9641 27.2515 15.7163C26.9789 15.4686 26.8426 15.1129 26.8426 14.6494V8.75098C26.8426 7.8718 26.6908 7.24439 26.387 6.86875C26.0833 6.49311 25.6199 6.30529 24.9968 6.30529C24.2336 6.30529 23.6261 6.57303 23.1744 7.10851C22.7226 7.64403 22.4968 8.37534 22.4968 9.30243V14.6494C22.4968 15.1129 22.3605 15.4686 22.0879 15.7163C21.8153 15.9641 21.4687 16.088 21.0482 16.088C20.6276 16.088 20.2771 15.9641 19.9967 15.7163C19.7164 15.4686 19.5762 15.1129 19.5762 14.6494V8.75098C19.5762 7.8718 19.4243 7.24439 19.1206 6.86875C18.8168 6.49311 18.3534 6.30529 17.7304 6.30529C16.9671 6.30529 16.3635 6.57303 15.9196 7.10851C15.4757 7.64403 15.2537 8.37534 15.2537 9.30243V14.6494C15.2537 15.1129 15.1135 15.4686 14.8331 15.7163C14.5528 15.9641 14.2023 16.088 13.7817 16.088C13.3612 16.088 13.0146 15.9641 12.742 15.7163C12.4694 15.4686 12.3331 15.1129 12.3331 14.6494C12.3331 14.6494 12.3364 11.6181 12.3364 8.75415C12.3364 5.89017 13.3667 5.56833 13.9786 4.97362C14.5906 4.37891 16.4364 3.93155 17.3104 3.93155C18.1844 3.93155 20.7322 4.66686 21.3085 6.13744C21.6512 5.46608 23.3809 3.93155 25.0567 3.93155Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.3098 16.088C95.9856 16.088 97.3415 15.6081 98.0036 14.8887C98.6658 14.1693 99.0163 12.963 99.0163 11.2686V5.37019C99.0163 4.90662 98.8798 4.55096 98.6073 4.30319C98.3347 4.05542 97.9803 3.93155 97.5443 3.93155C97.1238 3.93155 96.777 4.05542 96.5044 4.30319C96.2319 4.55096 96.0957 4.90662 96.0957 5.37019V11.2686C96.0957 12.1477 95.9438 12.7751 95.6401 13.1508C95.3363 13.5264 94.873 13.7142 94.25 13.7142C93.4866 13.7142 92.879 13.4465 92.4272 12.911C91.9758 12.3755 91.7499 11.6442 91.7499 10.7171V5.37019C91.7499 4.90662 91.6135 4.55096 91.3409 4.30319C91.0684 4.05542 90.7218 3.93155 90.3013 3.93155C89.8805 3.93155 89.53 4.05542 89.2499 4.30319C88.9695 4.55096 88.8291 4.90662 88.8291 5.37019V11.2686C88.8291 12.1477 88.6775 12.7751 88.3737 13.1508C88.0699 13.5264 87.6063 13.7142 86.9833 13.7142C86.2202 13.7142 85.6166 13.4465 85.1727 12.911C84.7289 12.3755 84.5066 11.6442 84.5066 10.7171V5.37019C84.5066 4.90662 84.3665 4.55096 84.0861 4.30319C83.8056 4.05542 83.4552 3.93155 83.0347 3.93155C82.6142 3.93155 82.2676 4.05542 81.9951 4.30319C81.7225 4.55096 81.5861 4.90662 81.5861 5.37019C81.5861 5.37019 81.5893 8.40143 81.5893 11.2654C81.5893 14.1294 82.6197 14.4512 83.2316 15.0459C83.8435 15.6407 85.6896 16.088 86.5634 16.088C87.4373 16.088 89.985 15.3527 90.5614 13.8821C90.9043 14.5535 92.634 16.088 94.3098 16.088Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.9408 16.136C36.7727 16.136 35.7485 15.8882 34.8684 15.3927C33.9883 14.8972 33.3067 14.1858 32.8239 13.2587C32.3411 12.3316 32.0996 11.2526 32.0996 10.0218C32.0996 8.79092 32.3411 7.71595 32.8239 6.79683C33.3067 5.8777 33.9883 5.17037 34.8684 4.67483C35.7485 4.17932 36.7727 3.93155 37.9408 3.93155C39.109 3.93155 40.1331 4.17932 41.0133 4.67483C41.8934 5.17037 42.571 5.8777 43.0461 6.79683C43.5212 7.71595 43.7586 8.79092 43.7586 10.0218C43.7586 11.2526 43.5212 12.3316 43.0461 13.2587C42.571 14.1858 41.8934 14.8972 41.0133 15.3927C40.1331 15.8882 39.109 16.136 37.9408 16.136ZM37.9175 13.8102C38.8677 13.8102 39.592 13.4905 40.0904 12.8511C40.5888 12.2117 40.838 11.2686 40.838 10.0218C40.838 8.79092 40.5888 7.85182 40.0904 7.20444C39.592 6.55706 38.8756 6.23337 37.9408 6.23337C37.0061 6.23337 36.2858 6.55706 35.7794 7.20444C35.2735 7.85182 35.0203 8.79092 35.0203 10.0218C35.0203 11.2686 35.2695 12.2117 35.7679 12.8511C36.2664 13.4905 36.9828 13.8102 37.9175 13.8102Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.3754 16.136C50.2072 16.136 49.1831 15.8882 48.303 15.3927C47.4228 14.8972 46.7416 14.1858 46.2586 13.2587C45.7756 12.3316 45.5343 11.2526 45.5343 10.0218C45.5343 8.79092 45.7756 7.71595 46.2586 6.79683C46.7416 5.8777 47.4228 5.17037 48.303 4.67483C49.1831 4.17932 50.2072 3.93155 51.3754 3.93155C52.5439 3.93155 53.568 4.17932 54.4478 4.67483C55.328 5.17037 56.0056 5.8777 56.4807 6.79683C56.9558 7.71595 57.1935 8.79092 57.1935 10.0218C57.1935 11.2526 56.9558 12.3316 56.4807 13.2587C56.0056 14.1858 55.328 14.8972 54.4478 15.3927C53.568 15.8882 52.5439 16.136 51.3754 16.136ZM51.3521 13.8102C52.3022 13.8102 53.0265 13.4905 53.525 12.8511C54.0234 12.2117 54.2726 11.2686 54.2726 10.0218C54.2726 8.79092 54.0234 7.85182 53.525 7.20444C53.0265 6.55706 52.3101 6.23337 51.3754 6.23337C50.441 6.23337 49.7207 6.55706 49.2143 7.20444C48.708 7.85182 48.4549 8.79092 48.4549 10.0218C48.4549 11.2686 48.7041 12.2117 49.2025 12.8511C49.7009 13.4905 50.4177 13.8102 51.3521 13.8102Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0481 13.8581C65.827 13.9061 66.2163 14.2658 66.2163 14.9371C66.2163 15.3208 66.0644 15.6125 65.7606 15.8123C65.4568 16.0121 65.0248 16.096 64.4639 16.0641L63.8329 16.0161C61.2162 15.8243 59.908 14.3857 59.908 11.7002V2.01339C59.908 1.5818 60.0401 1.23811 60.3051 0.982366C60.5698 0.726594 60.9282 0.598724 61.3799 0.598724C61.8159 0.598724 62.1663 0.726594 62.4313 0.982366C62.696 1.23811 62.8285 1.5818 62.8285 2.01339V4.21928H64.8143C65.2348 4.21928 65.558 4.31521 65.7839 4.50703C66.0098 4.69882 66.1229 4.97856 66.1229 5.34623C66.1229 5.71387 66.0098 5.99362 65.7839 6.18544C65.558 6.37722 65.2348 6.47315 64.8143 6.47315H62.8285V11.916C62.8285 13.0989 63.3578 13.7303 64.4172 13.8102L65.0481 13.8581Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.2501 3.93158C77.9293 3.93158 79.2691 5.53803 79.2691 8.751V14.6494C79.2691 15.097 79.1406 15.4486 78.8835 15.7044C78.6264 15.9601 78.2719 16.088 77.8205 16.088C77.3688 16.088 77.0104 15.9601 76.7457 15.7044C76.4807 15.4486 76.3482 15.097 76.3482 14.6494V8.751C76.3482 7.9038 76.1809 7.28439 75.8462 6.89275C75.5112 6.50113 74.9854 6.30531 74.269 6.30531C73.4277 6.30531 72.754 6.57706 72.2477 7.12054C71.7417 7.66403 71.4885 8.39134 71.4885 9.30246V14.6494C71.4885 15.097 71.356 15.4486 71.0914 15.7044C70.8264 15.9601 70.4683 16.088 70.0166 16.088C69.5649 16.088 69.2104 15.9601 68.9533 15.7044C68.6965 15.4486 68.568 15.097 68.568 14.6494V2.02054C68.568 1.60493 68.7041 1.26926 68.9767 1.01349C69.2492 0.757752 69.6037 0.629883 70.0399 0.629883C70.4759 0.629883 70.8264 0.74975 71.0914 0.989518C71.356 1.22929 71.4885 1.55698 71.4885 1.9726V5.84975C71.8778 5.22634 72.396 4.75077 73.0424 4.42311C73.6887 4.09542 74.4245 3.93158 75.2501 3.93158Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.59 12.7312C110.855 12.7312 111.069 12.8351 111.233 13.0429C111.396 13.2507 111.478 13.5304 111.478 13.8821C111.478 14.3776 111.19 14.7933 110.614 15.1289C110.084 15.4326 109.484 15.6764 108.815 15.8602C108.145 16.044 107.506 16.136 106.899 16.136C105.061 16.136 103.604 15.5925 102.53 14.5055C101.455 13.4185 100.917 11.9319 100.917 10.0457C100.917 8.84687 101.151 7.78387 101.618 6.85678C102.085 5.92965 102.744 5.21034 103.593 4.6988C104.441 4.18729 105.403 3.93155 106.478 3.93155C107.506 3.93155 108.402 4.16331 109.165 4.62688C109.928 5.09044 110.52 5.74583 110.941 6.593C111.361 7.44021 111.572 8.43926 111.572 9.59018C111.572 10.2775 111.276 10.6212 110.684 10.6212H103.791C103.884 11.7242 104.188 12.5354 104.702 13.0549C105.216 13.5744 105.964 13.8341 106.945 13.8341C107.444 13.8341 107.884 13.7702 108.265 13.6423C108.647 13.5145 109.079 13.3386 109.562 13.1148C110.029 12.8591 110.372 12.7312 110.59 12.7312ZM106.548 6.04155C105.754 6.04155 105.119 6.29728 104.644 6.8088C104.169 7.32034 103.884 8.05562 103.791 9.01472H109.072C109.04 8.03964 108.807 7.30034 108.371 6.79683C107.934 6.29332 107.327 6.04155 106.548 6.04155Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.371 9.29895C113.364 9.25378 113.36 9.20802 113.358 9.16176C113.357 9.14037 113.357 9.11894 113.357 9.09751V2.15291C113.358 2.0958 113.357 2.08145 113.362 2.02451C113.421 1.32303 114.01 0.705573 114.707 0.644697C114.828 0.634082 114.856 0.63977 114.894 0.640596C114.95 0.644234 114.964 0.644234 115.019 0.651508C115.677 0.737978 116.244 1.32849 116.302 2.02451C116.306 2.08145 116.306 2.0958 116.307 2.15291V4.56937C117.142 4.14416 118.1 3.93157 119.182 3.93157C120.351 3.93157 121.375 4.17934 122.255 4.67485C123.135 5.17039 123.813 5.87772 124.288 6.79685C124.763 7.71598 125 8.79095 125 10.0218C125 11.2526 124.763 12.3316 124.288 13.2587C123.813 14.1858 123.135 14.8972 122.255 15.3927C121.375 15.8882 120.351 16.136 119.182 16.136C118.014 16.136 116.99 15.8882 116.11 15.3927C115.23 14.8972 114.549 14.1858 114.066 13.2587C113.583 12.3316 113.341 11.2526 113.341 10.0218C113.341 9.77455 113.351 9.53363 113.371 9.29895ZM119.159 13.8102C120.109 13.8102 120.834 13.4905 121.332 12.8511C121.83 12.2117 122.08 11.2686 122.08 10.0218C122.08 8.79095 121.83 7.85185 121.332 7.20447C120.834 6.55708 120.117 6.23339 119.182 6.23339C118.248 6.23339 117.527 6.55708 117.021 7.20447C116.515 7.85185 116.262 8.79095 116.262 10.0218C116.262 11.2686 116.511 12.2117 117.009 12.8511C117.508 13.4905 118.225 13.8102 119.159 13.8102Z"
      fill="currentColor"
    />
  </SVG>
)
