import React, { FC } from "react"

import { SVG } from "./SVG"

export const VectorShapeToothSmooth: FC = props => (
  <SVG {...props} width="559" height="202" viewBox="0 0 559 202" fill="none">
    <path
      d="M221.982 198.225C274.36 170.678 292.969 145.445 417.795 145.445L551.506 80.2413C599.795 30.4448 391.296 -59.0553 31.0059 56.4793C-87.8401 94.5899 169.603 225.771 221.982 198.225Z"
      fill="url(#VectorShapeToothSmooth)"
      fillOpacity="0.42"
    />
    <defs>
      <linearGradient
        id="VectorShapeToothSmooth"
        x1="327.14"
        y1="-143.859"
        x2="44.7138"
        y2="33.771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E83D1" />
        <stop offset="1" stopColor="#9E5DF1" />
      </linearGradient>
    </defs>
  </SVG>
)
